import React, { useState } from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import randomstring from 'randomstring';
import { EditableRow, EditableCell } from './UpdateUserComponents';

const UsersList = ({ users, onUpdate, onRemove, viewport }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [dataSource, setDataSource] = useState(users);

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      sorter: (a, b) => a?.firstName?.localeCompare(b?.firstName),
      width: ['mobileLandscape', 'mobilePortrait'].includes(viewport) ? 150 : 175,
      fixed: 'left',
      defaultSortOrder: 'ascend',
      editable: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      sorter: (a, b) => a?.lastName?.localeCompare(b?.lastName),
      width: 155,
      editable: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      width: 205,
      editable: true,
    },
    {
      title: 'Reservations',
      dataIndex: 'userId',
      width: 145,
      render: (_, record) => (
        <Link 
          to={`/report/user/${record?.uid}`} 
          className="view-report"
        >
          View Reservations
        </Link>
      ),
    },
    {
      title: 'Remove User',
      dataIndex: 'remove',
      width: 130,
      render: (_, record) => (
        <span 
          role="button"
          onClick={() => onRemove(record)}
          className="remove"
        >
          Remove
        </span>
      ),
    },
  ];

  const handleAdd = () => {
    const uid = randomstring.generate(28);
    const userData = {
      uid,
      key: uid,
      firstName: 'FirstName',
      lastName: 'LastName',
      email: `edit.me.${randomstring.generate(5)}@email.com`,
      role: 'user',
    };
    setDataSource([...dataSource, userData]);
    onUpdate(userData, 'create');
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    onUpdate(row);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columnsMap = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div className="users-list-wrapper">
      <button onClick={handleAdd} className="primary small add-user-btn">
        Add user
      </button>
      <Table
        scroll={{ x: 990 }}
        pagination={users?.length > 20 && {
          showSizeChanger: true,
          defaultPageSize: 20,
          pageSizeOptions: [10, 20, 30, 40, 50],
          onShowSizeChange: (current, size) => {
            setPage(current);
            setPageSize(size)
          },
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize)
          },
          showTotal: (total, range) => {
            let pageCount = Math.ceil(total / pageSize);
            if (total === 0 && pageCount === 0) {
              pageCount = 1;
            }
            return `${page} of ${pageCount} page${pageCount > 1 ? 's' : ''}`;
          }
        }}
        rowClassName={() => 'editable-row'}
        dataSource={dataSource}
        columns={columnsMap}
        components={components}
        rowKey={record => record?.uid}
      />
      {
        viewport !== 'desktop' && (
          <span className="table-scroll-hint">
            {'Scroll sideways to view more data -->'}
          </span>
        )
      }
    </div>
  )
};

export default UsersList;
