import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
// import searchIcon from "../../assets/bike/searchIcon.svg";

const Search = ({ callback, placeholder, label = null }) => {
  return (
    <div className='input-search'>
      {
        label && <span className="search-label">{label}</span>
      }
      <Input 
        size="large" 
        placeholder={placeholder} 
        // prefix={<img src={searchIcon} alt="" className="search-icon" />} 
        prefix={<SearchOutlined />} 
        onChange={e => {
          callback(e.target.value);
        }}
      />
    </div>
  )
};

export default Search;
