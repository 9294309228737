import React from 'react';
import sortDirectionIcon from "../../assets/bike/sortDirectionIcon.svg";

const BikesFilter = ({ onChangeSort, onChangeSortDirection, sortBy, direction, role }) => {
  return (
    <div className="bikes-filter-wrapper">
      <p className="hint">Sort By</p>
      <button 
        className={`small white ${sortBy === 'availability' ? 'selected' : ''}`} 
        onClick={() => onChangeSort('availability')}
      >Availability</button>
      <button 
        className={`small white ${sortBy === 'model' ? 'selected' : ''}`} 
        onClick={() => onChangeSort('model')}
      >Model</button>
      {
        role === 'manager' && (
          <button 
            className={`small white ${sortBy === 'dateCreated' ? 'selected' : ''}`} 
            onClick={() => onChangeSort('dateCreated')}
          >Date Created</button>
        )
      }
      <button 
        className={`small white ${sortBy === 'rating' ? 'selected' : ''}`} 
        onClick={() => onChangeSort('rating')}
      >Rating</button>
      <button 
        className={`small white sort-direction`}
        onClick={() => onChangeSortDirection(direction === 'asc' ? 'desc' : 'asc')}
      ><img src={sortDirectionIcon} alt="" className="sort-direction-icon" /></button>
    </div>
  )
};

export default BikesFilter;
