import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Spin } from 'antd';
import callApi from '../utils/callApi';
import { Layout, Search, UserBikesList, BikeUsersList, BikeSelector, UserSelector } from "../components";
import rotateIcon from "../assets/report/rotate.svg";
import useWindowDimensions from '../utils/useWindowDimensions';

const Report = () => {
  let history = useHistory();
  const params = useParams();
  const { viewport } = useWindowDimensions();

  const { bikeId, userId } = params;
  const [loader, setLoader] = useState(true);
  const [reservations, setReservations] = useState();
  const [user, setUser] = useState();
  const [users, setUsers] = useState();
  const [bike, setBike] = useState();
  const [bikes, setBikes] = useState();
  const [filteredReservations, setFilteredReservations] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    loadReportData();
  }, [bikeId, userId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!searchQuery) {
      setFilteredReservations(reservations);
    } else {
      bikeId 
      ? setFilteredReservations(
          reservations?.filter(entry => {
            const firstNameMatch = entry?.user?.firstName?.toLowerCase().includes(searchQuery.toLowerCase());
            const lastNameMatch = entry?.user?.lastName?.toLowerCase().includes(searchQuery.toLowerCase());
            const emailMatch = entry?.user?.email?.toLowerCase().includes(searchQuery.toLowerCase());
            return firstNameMatch || lastNameMatch || emailMatch;
          })
        ) 
      : setFilteredReservations(
          reservations?.filter(entry => {
            const modelMatch = entry?.bike?.model?.toLowerCase().includes(searchQuery.toLowerCase());
            const colorMatch = entry?.bike?.color?.toLowerCase().includes(searchQuery.toLowerCase());
            const locationMatch = entry?.bike?.location?.toLowerCase().includes(searchQuery.toLowerCase());
            const descriptionMatch = entry?.bike?.description?.toLowerCase().includes(searchQuery.toLowerCase());
            return modelMatch || colorMatch || locationMatch || descriptionMatch;
          })
        ) 
    }
  }, [searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  async function loadReportData() {
    setLoader(true);
    const response = await callApi('post', 'report', { bikeId, userId });
    console.log('loadReportData response', response);
    setBike(response?.bike);
    setBikes(response?.bikes);
    setUser(response?.user);
    setUsers(response?.users);
    setReservations(response?.reservations);
    setFilteredReservations(response?.reservations);
    setLoader(false);
  }

  const onBikeChange = async newBikeId => history.push(`/report/bike/${newBikeId}`);
  const onUserChange = async newUserId => history.push(`/report/user/${newUserId}`);

  return (
    <Layout>
      <div className="overall-report-page bike">
        {
          loader 
            ? <Spin size="large" className="full-page-loader" /> 
            : (
              <React.Fragment>
                {
                  bikeId ? (
                    <BikeSelector 
                      bikes={bikes}
                      bikeId={bikeId}
                      onChange={onBikeChange}
                    />
                  ) : (
                    <UserSelector 
                      users={users}
                      userId={userId}
                      onChange={onUserChange}
                    />
                  )
                }

                {
                  (!reservations || reservations?.length === 0) && (
                    <h3 className="no-reservations">No reservations yet</h3>
                  )
                }
                <div className="search-actions">
                  <Search 
                    callback={setSearchQuery} 
                    placeholder={`Type ${userId ? 'Bike Model' : 'User Name'}`}
                    label={`Search for ${userId ? 'Bike' : 'User'}`}
                  />
                  {
                    viewport === 'tabletPortrait' && (
                      <div className="rotate-hint">
                        <img className="rotate" src={rotateIcon} alt="" />
                        Rotate tablet to view reservations
                      </div>
                    )
                  }
                </div>
                {
                  bikeId ? (
                    <BikeUsersList 
                      reservations={filteredReservations}
                      bike={bike}
                      viewport={viewport}
                    />
                  ) : (
                    <UserBikesList 
                      reservations={filteredReservations}
                      user={user}
                      viewport={viewport}
                    />
                  )
                }

              </React.Fragment>
            )
        }
      </div>
    </Layout>
  )
}

export default Report;
