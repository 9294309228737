import { useState, useEffect } from 'react';

const breakpoints = {
  mobilePortrait: 390,
  mobileLandscape: 580,
  tabletPortrait: 820,
  tabletLandscape: 1180,
  desktop: 1600,
};

function getWindowDimensions() {
  const { innerWidth: screenWidth, innerHeight: height } = window;
  let viewport = 'desktop';
  if (screenWidth <= breakpoints.mobilePortrait) {
    viewport = 'mobilePortrait';
  } else if (screenWidth <= breakpoints.mobileLandscape) {
    viewport = 'mobileLandscape';
  } else if (screenWidth <= breakpoints.tabletPortrait) {
    viewport = 'tabletPortrait';
  } else if (screenWidth <= breakpoints.tabletLandscape) {
    viewport = 'tabletLandscape';
  }

  return {
    screenWidth,
    height,
    viewport
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
