import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Image, Skeleton, Rate } from 'antd';
import arrow from "../../assets/common/arrow-right.svg";
import locationIcon from "../../assets/bike/locationIcon.svg";
import timeLimitIcon from "../../assets/bike/timeLimitIcon.svg";
import colorIcon from "../../assets/bike/colorIcon.svg";
import ratingIcon from "../../assets/bike/ratingIcon.svg";
import missingThumbnail from "../../assets/bike/missing.png";
import { getTimeLimit } from '../../utils/time';
import { isValidGPS } from '../../utils/helpers';
import { googleMaps } from '../../config.json';

const UserBikeCard = ({ bike, rate = null }) => {
  return (
    <Card style={{ width: 400 }} className="user-view">
      <div className="bike-card-user">
        <div className={`bike-card-header ${bike?.available} ${rate && 'rates'}`}>
          {
            rate ? <p>Rate this bike</p> : <p>{!bike?.available && 'Not '} Available</p>
          }
        </div>
        <div className="bike-card-body">
          <div className="bike-card-preview">
            <Image
              width={350}
              src={bike?.fileUrl}
              alt={bike?.title}
              placeholder={<Skeleton.Avatar active size="large" shape="square" className="skeleton-bikePreview" />}
              fallback={missingThumbnail}
            />
          </div>
          <div className="bike-card-title">
            <p>{bike?.model}</p>
          </div>
          <div className="bike-card-subtitle">
            <p>{bike?.description}</p>
          </div>
          {
            rate ? (
              <div className="bike-card-content">
                <Rate allowHalf onChange={rating => rate(rating, bike?.bikeId)}/>
              </div>
            ) : (
              <React.Fragment>
                <div className="bike-card-content">
                  <img className="icon" src={locationIcon} alt="" />
                  <p>Location: &nbsp;
                    {
                      typeof bike?.location === 'string' && isValidGPS(bike?.location) 
                      ? (
                        <a 
                          href={`${googleMaps}${bike?.location}`} 
                          target="_blank"
                          rel="noopener noreferrer"
                          className="location"
                        >
                          {bike?.location}
                        </a>
                      ) : bike?.location
                    }
                  </p>
                </div>
                <div className="bike-card-content">
                  <img className="icon" src={colorIcon} alt="" />
                  <p>Bike color: {bike?.color}</p>
                </div>
                {
                  'timeLimit' in bike && (
                    <div className="bike-card-content">
                      <img className="icon" src={timeLimitIcon} alt="" />
                      <p>{getTimeLimit(bike?.timeLimit)}</p>
                    </div>
                  )
                }
                <div className="bike-card-content">
                  {
                    bike?.rating ? <><Rate allowHalf disabled defaultValue={bike?.rating} /> ({bike?.rating})</>
                    : (<>
                      <img className="icon" src={ratingIcon} alt="" />
                      <p>Not rated</p>
                    </>)
                  }
                </div>
                <Link to={`/reservation/${bike?.bikeId}`}>
                  <button className="primary medium bike-cta" disabled={!bike?.available}>
                    Make reservation &nbsp;&nbsp;
                    <img className="arrow" src={arrow} alt="" />
                  </button>
                </Link>
              </React.Fragment>
            )
          }

        </div>
      </div>
    </Card>
  )
};

export default UserBikeCard;
