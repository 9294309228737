import React, { useEffect, useState, useContext } from 'react';
import { Spin, notification } from 'antd';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AppContext } from '../context/globalState';
import { Layout, ReservationCallout, FullAccessCallout } from "../components";
import callApi from '../utils/callApi';
import cards from "../assets/reservation/cards.png";
import { reservationPrice } from '../config.json';

const Reservation = () => {
  const { user } = useContext(AppContext);
  const { status, bikeId, sessionId } = useParams();
  const [loader, setLoader] = useState(true);
  const [reservation, setReservation] = useState();
  const [price, setPrice] = useState(reservationPrice);
  const [bike, setBike] = useState(reservationPrice);
  const [reserved, setReserved] = useState([]);

  useEffect(() => {
    async function loadData() {
      await fetchData();
    }
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    setLoader(true);
    const bikeResponse = await callApi('get', `bike?bikeId=${bikeId}`);
    if (!bikeResponse?.error && bikeResponse?.status !== 'error') {
      bikeResponse?.bike && setBike(bikeResponse?.bike);
      bikeResponse?.reserved && setReserved(bikeResponse?.reserved);
    }
    setLoader(false);
  }

  useEffect(() => {
    const loadPaymentData = async () => {
      const response = await callApi('get', `payment?sessionId=${sessionId}`);
      console.log('loadPaymentData response', response)
      if (!response?.error && response?.status !== 'error') {
        setPrice(response?.price);
        if (response?.reservation && !isEmpty(response?.reservation)) {
          setReservation(response?.reservation);
        }
      }
      setLoader(false);
    }

    if (user && !isEmpty(user)) {
      loadPaymentData();
      if (user?.reservationDetails && !isEmpty(user?.reservationDetails)) {
        setReservation(user?.reservationDetails);
      }
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const startCheckout = async (time) => {
    setLoader(true);
    const response = await callApi('put', 'payment', { domain: window.location.origin, bikeId, time });
    if (!response?.error && response?.status !== 'error') {
      console.log('Redirecting to', response?.redirect);
      window.location.href = response?.redirect;
    } else {
      notification['error']({
        duration: 10,
        message: 'Reservation error',
        description: response?.error
      });
    }
    setLoader(false);
  }

  const cancelCallout = () => (
    <div className="reservation-page-cancel">
      <h3>Your payment was unsuccessful or cancelled.</h3>
      <div className="reservation-page-cancel-cta">
        {/* <button onClick={startCheckout} className="primary medium">Try Again</button> */}
        <Link 
          to={{ pathname: 'mailto:hello@lexer.dev' }}
          target="_blank"
        >
          <button className="primary medium dark">Contact Support</button>
        </Link>
      </div>
    </div>
  )

  const cancelReservation = async () => {
    setLoader(true);
    const response = await callApi('delete', `payment?paymentId=${reservation?.paymentId}`);
    if (!response?.error && response?.status !== 'error') {
      setReservation(response?.reservationDetails);
    } else {
      notification['error']({
        duration: 10,
        message: 'Reservation error',
        description: response?.error
      });
    }
    setLoader(false);
  }

  const successCallout = () => (
    <React.Fragment>
      { 
        status === 'success' && reservation?.status !== 'Reservation cancelled' && (
          <div className="reservation-page-success">
            <h3>Payment successful</h3>
          </div>
        )
      }
      <FullAccessCallout 
        reservation={reservation} 
        price={price} 
        onCancel={cancelReservation}
        bike={bike}
      />
    </React.Fragment>
  )

  return (
    <Layout>
      <div className="reservation-page">
        {
          loader 
            ? <Spin size="large" className="full-page-loader" /> 
            : (
              <React.Fragment>
                { status === 'cancel' && cancelCallout() }
                {
                  status === 'success' ? successCallout() : (
                    <React.Fragment>
                      <ReservationCallout 
                        price={price} 
                        callback={startCheckout} 
                        reservation={reservation}
                        bike={bike}
                        reserved={reserved}
                      />
                      <img src={cards} alt="Secure checkout" className="secure-checkout-img" />
                    </React.Fragment>
                  )
                }
              </React.Fragment>
            )
        }
      </div>
    </Layout>
  )
}

export default Reservation;
