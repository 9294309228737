import React, { useState } from 'react';
import { Drawer } from 'antd';
import { Legal } from '../../pages';

const Footer = () => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => setVisible(true);
  const onClose = () => setVisible(false);
  
  return (
    <footer className="footer">
      <div>
        Copyright © Alexey Sobolev
        (<a className="termsOfUse-link default" href="https://lexer.dev" target="_blank" rel="noopener noreferrer">
          lexer.dev
        </a>)
      </div>
      <span role="button" className='link' onClick={showDrawer}>Copyright Notice</span>
      <Drawer
        title="Copyright Notice"
        placement="bottom"
        height={500}
        onClose={onClose}
        visible={visible}
      >
        <Legal />
      </Drawer>
    </footer>
  );
}

export default Footer;
