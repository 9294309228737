import warning from "../../assets/common/warning.svg";

const SignUpError = ({ errors }) => (
  <div className="errors-wrapper">
    {
      errors?.filter(error => error !== '').map((error, index) => (
        <div className="form-error" key={`${error}-${index}`}>
          <img className="warning" src={warning} alt="warning" />
          <span>{ typeof error === 'string' ? error : JSON.stringify(error) }</span>
          <div />
        </div>
      ))
    }
  </div>
);

export default SignUpError;
