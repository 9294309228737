// import { Link } from 'react-router-dom';
// import image from "../assets/common/not-found.png";
import { Layout } from "../components";

const NotFoundPage = () => (
  <Layout>
    <div className="not-found-page">
      {/* <img src={image} alt="Page not found" className="not-found-image" /> */}
      <h1>Oops! The page was not found.</h1>
      {/* <Link to="/dashboard">
        <button className='primary large'>
          Go to home
        </button>
      </Link> */}
    </div>
  </Layout>
);

export default NotFoundPage;
