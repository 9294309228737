import React, { useContext, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Spin } from 'antd';
import { AppContext } from "../context/globalState";
import { logout } from "../utils/firebase";
import { Layout, LoginComponent } from '../components';

const Login = () => {
  let history = useHistory();
  const { loading, setLoggedIn } = useContext(AppContext);
  const isLogout = history.location.pathname === '/logout';

  useEffect(() => {
    if (history?.location?.hash === '#logout') {
      processLogout();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const processLogout = async () => {
    console.log('Logout')
    setLoggedIn(false);
    await localStorage.clear();
    await logout();
    history.push('/login');
  }

  if (loading) {
    return <Spin size="large" className="full-page-loader" />
  }
  return (
    <Layout>
      <div className="signUp-main-section">
        {
          isLogout ? (
            <React.Fragment>
            <h2>You’ve been logged out.</h2> 
            <div className="sign-in-switcher">
              Login again below.
            </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h2>Login to Bike Rental</h2>
              <div className="sign-in-switcher">
                Don’t have an account? &nbsp;
                <Link to="/join-now" className="join-now default">
                  Join Now
                </Link>
              </div>
            </React.Fragment>
          )
        }
        <br />
        <LoginComponent />
      </div>
    </Layout>
  );
};

export default Login;
