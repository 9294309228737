import React from 'react';
import { Skeleton } from 'antd';
import arrowRight from "../../assets/common/arrow-right.svg";
import arrowLeft from "../../assets/common/arrow-left.svg";

const ConfigureBikeSkeleton = () => {
  return (
    <div className="bike-configure-form skeleton">
      <div className="form-main">
        <Skeleton active title={{ width: 130 }} paragraph={false} />
        <Skeleton active title={{ width: 426 }} paragraph={false} className="skeleton-long" />
        <Skeleton active title={{ width: 130 }} paragraph={false} />
        <Skeleton active title={{ width: 426 }} paragraph={false} className="skeleton-long large" />
        <Skeleton active title={{ width: 130 }} paragraph={false} />
        <Skeleton active title={{ width: 426 }} paragraph={false} className="skeleton-long" />

        <div className="skeleton-switch">
          <Skeleton active title={{ width: 130 }} paragraph={false} />
          <Skeleton active title={{ width: 50 }} paragraph={false} className="skeleton-switch-item" />
        </div>
        <div className="skeleton-switch">
          <Skeleton active title={{ width: 130 }} paragraph={false} />
          <Skeleton active title={{ width: 50 }} paragraph={false} className="skeleton-switch-item" />
        </div>
        <div className="skeleton-switch">
          <Skeleton active title={{ width: 130 }} paragraph={false} />
          <Skeleton active title={{ width: 50 }} paragraph={false} className="skeleton-switch-item" />
        </div>
      </div>

      <div className="form-column cta">
        <div className="ant-form-item-control-input-content">
          <button className="primary dark">
            <img className="arrow" src={arrowLeft} alt="" />
            Back
          </button>
          <button className="primary newTab" type="submit">
            Start
            <img className="arrow" src={arrowRight} alt="" />
          </button>
        </div>
      </div>
    </div>
  )
};

export default ConfigureBikeSkeleton;
