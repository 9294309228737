import React, { useEffect, useState } from "react";
import { Form, Input, notification } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import randomstring from 'randomstring';
import { isEmpty } from 'lodash';

const UploadByLink = ({ onFileListUpdate }) => {
  const [addLinkForm] = Form.useForm();
  const [domains, setDomains] = useState([]);

  useEffect(() => {
    async function fetchDomains() {
      fetch('https://data.iana.org/TLD/tlds-alpha-by-domain.txt')
        .then(response => {
          if (!response.ok) {
              throw new Error("HTTP error " + response.status);
          }
          return response.text();
        })
        .then(text => {
          const domains = text.split('\n');
          domains.shift();
          domains.pop();
          setDomains(domains)
        })
        .catch(error => {
            console.error(error)
        });
    }

    fetchDomains();
  }, []);

  const onUpdate = (key, value) => {
    const fileNameRegex = /([\w\d_-]*)\.?[^\\\/]*$/i; // eslint-disable-line no-useless-escape 
    const fileTypeRegex = /\.([0-9a-z]+)(?:[\?#]|$)/i; // eslint-disable-line no-useless-escape 
    // const urlRegex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    // const urlRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/i;
    const urlRegex = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

    const linksObj = {}
    value
      .filter(entry => entry?.name?.length === 1 && entry?.value)
      .forEach(entry => linksObj[entry?.name?.[0]] = entry?.value );

    linksObj.links = linksObj.links.filter(entry => entry && entry.length);
    
    const validExtensions = ['png', 'jpg', 'jpeg', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'];
    const fileList = linksObj.links.map((url, uid) => {
      const adjustedUrl = url.replace(/%2F/gi, '/');
      if (!urlRegex.test(adjustedUrl)) return null;
      const matchGroup = adjustedUrl.match(fileTypeRegex);

      let type;
      if (urlRegex.test(adjustedUrl)) {
        type = 'html';
      }
      if (matchGroup?.length && !isEmpty(matchGroup) && matchGroup?.[1]) {
        if (validExtensions.includes(matchGroup?.[1])) {
          type = matchGroup?.[1];
        } else if (domains.includes(matchGroup?.[1]?.toUpperCase())) {
          type = 'html';
        }
      } else if (urlRegex.test(adjustedUrl)) {
          type = 'html';
      } else {
        notifyError();
        return null;
      }

      let name;
      if (type === 'html') {
        name = adjustedUrl.match(fileNameRegex)?.[0];
        if (!name) {
          name = adjustedUrl;
        }
      } else {
        name = `${adjustedUrl.match(fileNameRegex)?.[1]}.${type}`;
      }
      
      if (name.endsWith('.')) return null;

      return {
        name,
        status: 'done',
        uid,
        type,
        url
      }
    }).filter(obj => obj); // remove null values

    // Check for URLs and return the first one
    const urlObjects = fileList.filter(obj => obj?.type === 'html');
    if (urlObjects?.length > 1) {
      notifyInfo();
    }
    urlObjects?.length ? onFileListUpdate([urlObjects?.[0]]) : onFileListUpdate(fileList);
  }

  const notifyError = () => {
    const key = randomstring.generate(8);
    notification['error']({
      duration: 10,
      placement: 'bottomRight',
      message: 'File Type Not Supported',
      btn: (
        <button 
          className="primary medium notification-close"
          onClick={() => notification.close(key)}
        >Ok</button>
      ),
      key,
      description: 'Please make sure the link you are pasting ends in .pdf, .docx, .ppt, .png or .xlsx'
    });
  }

  const notifyInfo = () => {
    const key = randomstring.generate(8);
    notification['info']({
      duration: 10,
      placement: 'bottomRight',
      message: 'Only one URL can be uploaded per bike',
      key,
      btn: (
        <button 
          className="primary medium notification-close"
          onClick={() => notification.close(key)}
        >Ok</button>
      ),
      description: (
        <span>
          If you would prefer to upload multiple files, click on the <strong>File tab</strong> and upload multiple bikes from your computer.
        </span>
      )
    });
  }

  return (
    <Form
      form={addLinkForm}
      size="large"
      layout="vertical"
      name="add-link-form"
      hideRequiredMark
      onFieldsChange={onUpdate}
      initialValues={{ links: [''] }}
    >
      <Form.List name='links'>
        {(fields, { add, remove }, { errors }) => (
          <div>
            <div className="add-link">
              <div className="left-col">
                <p className="add-link-label">Enter file or webpage URL</p>
              </div>
            </div>
            {fields.map((field) => (
              <Form.Item required={false} key={field.key}>
                <div className='input-wrapper-dynamic'>
                  <Form.Item {...field} noStyle>
                    <Input 
                      placeholder='Paste or type link here' 
                      className='rounded-input' 
                    />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <DeleteOutlined className='dynamic-delete-button' onClick={() => remove(field.name)} />
                  ) : null}
                </div>
              </Form.Item>
            ))}
          </div>
        )}
      </Form.List>
    </Form>
  );
};

export default UploadByLink;
