import React, { useState } from 'react';
import { Table } from 'antd';
import randomstring from 'randomstring';
import { EditableRow, EditableCell } from './UpdateUserComponents';

const ManagersList = ({ managers, onUpdate, onRemove, viewport }) => {
  const [dataSource, setDataSource] = useState(managers);

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      sorter: (a, b) => a?.firstName?.localeCompare(b?.firstName),
      width: ['mobileLandscape', 'mobilePortrait'].includes(viewport) ? 150 : 167,
      fixed: 'left',
      defaultSortOrder: 'ascend',
      editable: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      sorter: (a, b) => a?.lastName?.localeCompare(b?.lastName),
      width: 167,
      editable: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      width: 205,
      editable: true,
    },
    {
      title: 'Remove Manager',
      dataIndex: 'remove',
      width: 167,
      render: (_, record) => (
        <span 
          role="button"
          onClick={() => onRemove(record)}
          className="remove"
        >
          Remove
        </span>
      ),
    },
  ];

  const handleAdd = () => {
    const uid = randomstring.generate(28);
    const userData = {
      uid,
      key: uid,
      firstName: 'FirstName',
      lastName: 'LastName',
      email: `edit.me.${randomstring.generate(5)}@email.com`,
      role: 'manager',
    };
    setDataSource([...dataSource, userData]);
    onUpdate(userData, 'create');
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    onUpdate(row);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columnsMap = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div className="users-list-wrapper">
      <button onClick={handleAdd} className="primary small add-user-btn">
        Add manager
      </button>
      <Table
        scroll={{ x: 990 }}
        pagination={false}
        rowKey={record => record?.uid || record?.email}
        rowClassName={() => 'editable-row'}
        dataSource={dataSource}
        columns={columnsMap}
        components={components}
      />
      {
        viewport !== 'desktop' && (
          <span className="table-scroll-hint">
            {'Scroll sideways to view more data -->'}
          </span>
        )
      }
    </div>
  )
};

export default ManagersList;
