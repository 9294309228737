import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, InputNumber, Spin, Switch } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import configureBike from "../../assets/bike/configureBike.svg";
import { ConfigureBikeSkeleton } from "..";
import arrowRight from "../../assets/common/arrow-right.svg";
import arrowLeft from "../../assets/common/arrow-left.svg";
import callApi from '../../utils/callApi';

const ConfigureBikeForm = ({ buttonLabel = 'Start' }) => {
  let history = useHistory();

  const [configureForm] = Form.useForm();
  const params = useParams();
  const bikeId = params?.bikeId;
  const [bikeData, setBikeData] = useState({});
  const [showTimeLimit, setShowTimeLimit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);

  useEffect(() => {
    async function loadData() {
      await fetchData();
    }
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    setMainLoader(true);
    const bikeResponse = await callApi('get', `bike?bikeId=${bikeId}`);
    if (!bikeResponse?.error && bikeResponse?.status !== 'error') {
      bikeResponse?.bike && setBikeData(bikeResponse?.bike);

      configureForm.setFieldsValue({
        'time-limit': !!bikeResponse?.bike?.timeLimit,
        limit: Math.round(bikeResponse?.bike?.timeLimit / 60) || 15,
        model: bikeResponse?.bike?.model || '',
        color: bikeResponse?.bike?.color || '',
        location: bikeResponse?.bike?.location || '',
        description: bikeResponse?.bike?.description || '',
      });
      setShowTimeLimit(!!bikeResponse?.bike?.timeLimit);
    }
    setMainLoader(false);
  }

  const onFinish = async (values) => {
    let currentBikeId = bikeId;
    const payload = {
      bikeId: currentBikeId,
      model: values?.model,
      color: values?.color,
      location: values?.location,
      dateCreated: Date.now(),
      dateCreatedReadable: moment().format(),
    }

    if (values?.description) {
      payload.description = values?.description || null
    }
    
    if (values?.['time-limit']) {
      payload.timeLimit = Number(values?.limit) * 60;
    } else {
      payload.timeLimit = null;
    }

    console.log('Bike configuration', payload);
    setLoader(true);
    const response = await callApi('put', 'bike', payload);
    setLoader(false);

    console.log('onFinish response', response);
    if (!response?.error && response?.status !== 'error') {
      history.push('/dashboard');
    }
  }

  const onCancel = e => {
    e.preventDefault();
    history.goBack();
  }

  return (
    <React.Fragment>
      <div className="bike-configure-page">
        <div className="title-wrapper">
          <img src={configureBike} alt="configure Bike" className="configure-bike-img" />
          <div className="title-content-wrapper">
            <h2>Bike Settings</h2>
          </div>
        </div>
        {
          mainLoader ? <ConfigureBikeSkeleton /> : (
            <Form
              initialValues={{ 
                limit: Math.round(bikeData?.timeLimit / 60) || 120,
                model: bikeData?.model || '',
                description: bikeData?.description || '',
                color: bikeData?.color || '',
                location: bikeData?.location || '',
              }}
              form={configureForm}
              name="bike-configure" 
              onFinish={onFinish} 
              className="bike-configure-form"
              layout="vertical"
            >
              <div className="form-main">
                <Form.Item 
                  name="model" 
                  label="Bike Model" 
                  rules={[{ required: true, message: 'Bike Model is required!' }]}
                >
                  <Input placeholder="Enter bike model" />
                </Form.Item>
                <Form.Item 
                  name="color" 
                  label="Bike Color" 
                  rules={[{ required: true, message: 'Bike Color is required!' }]}
                >
                  <Input placeholder="Enter bike color" />
                </Form.Item>
                <Form.Item 
                  name="location" 
                  label="Bike Location" 
                  rules={[{ required: true, message: 'Bike Location is required!' }]}
                >
                  <Input placeholder="Enter bike location" />
                </Form.Item>
                <Form.Item name="description" label="Bike description">
                  <Input.TextArea rows={4} placeholder="Enter bike description" />
                </Form.Item>
                <div className="form-switch">
                  <Form.Item label="Reservation Time Limit" name="time-limit">
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      name="time-limit" 
                      onChange={setShowTimeLimit}
                      defaultChecked={bikeData?.timeLimit}
                    />
                  </Form.Item>

                  <div className="bike-limit-wrapper">
                    <Form.Item>
                      <Form.Item name="limit" noStyle>
                        {
                          showTimeLimit && <InputNumber min={1} max={240} />
                        }
                      </Form.Item>
                      {
                        showTimeLimit && <span> &nbsp; minute(s)</span>
                      }
                    </Form.Item>
                  </div>
                </div>
              </div>
              
              {
                loader 
                  ? <Spin size="large" /> 
                  : (
                    <div className="form-column cta">
                      <Form.Item>
                        <button className="primary dark" onClick={onCancel}>
                          <img className="arrow" src={arrowLeft} alt="" />
                          Back
                        </button>
                        <button className="primary" type="submit">
                          {buttonLabel}
                          <img className="arrow" src={arrowRight} alt="" />
                        </button>
                      </Form.Item>
                    </div>
                  )
              }
            </Form>
          )
        }
      </div>
    </React.Fragment>
  );
}

export default ConfigureBikeForm;
