const Header = ({ contentLeft, contentRight, contentMiddle }) => (
    <header className="page-header">
      <div className="header-left">
        { contentLeft }
      </div>
      <div className="header-middle">
        { contentMiddle }
      </div>
      <div className="header-right">
        { contentRight }
      </div>
    </header>
);

export default Header;
