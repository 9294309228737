import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from 'react-router';
import { AppContext } from "../../context/globalState";
import { signInWithGoogle, signInWithMicrosoft, signInWithCredentials } from "../../utils/firebase";
import { SignUpError } from '..';
import { Form, Input } from "antd";

import googleLogo from "../../assets/signup/google-logo.png";
import microsoftLogo from "../../assets/signup/microsoft-logo.png";

const LoginComponent = () => {
  const params = useParams();
  const { errors, setErrors, handleSignUp} = useContext(AppContext);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState("");
  const [loginForm] = Form.useForm();

  useEffect(() => {
    loginForm.setFieldsValue({ email: params?.email || '' });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = () => {
    signInWithCredentials("signIn", {email, password}, onFinishCallback, setLoginErrors);
  };

  const onFinishCallback = async (result) => {
    console.log('Call handleSignUp 33')
    await handleSignUp(result);
  };

  const setLoginErrors = (errorObj) => {
    if (typeof errorObj === 'string') {
      setErrors([errorObj]);
    } else if (typeof errorObj === 'object' && errorObj?.message) {
      setErrors([errorObj?.message]);
      console.log('Login err1', errorObj?.message)
    } else if (errorObj?.errorFields?.length) {
      const loginErrors = errorObj?.errorFields?.map(({ errors }) => errors[0].replace('!','.')) || [];
      setErrors(loginErrors);
      console.log('Login err2', loginErrors)

    }
  };

  return (
    <React.Fragment>
      <div className="signup-cta-wrapper">
        <button onClick={signInWithGoogle} className="google-login-btn" type="button">
          <img className="google-logo" src={googleLogo} alt="logo" />
          Login with Google
          <div />
        </button>
        <button onClick={signInWithMicrosoft} className="microsoft-login-btn" type="button">
          <img className="microsoft-logo" src={microsoftLogo} alt="logo" />
          Login with Microsoft
          <div />
        </button>
      </div>
      <br />
      <div>
        <SignUpError errors={errors} />
      </div>
      <Form 
        form={loginForm}
        size="large" 
        layout="vertical" 
        name="login-form" 
        hideRequiredMark 
        onFinish={onFinish} 
        onFinishFailed={setLoginErrors}
        validateTrigger="onSubmit"
      >
        <Form.Item
          name={["email"]}
          label="Email Address"
          value={'email'}
          onChange={(e) => setEmail(e.target.value.trim()) || loginForm.setFieldsValue({ email: e.target.value.trim() || '' })}
          rules={[
            {
              type: "email",
              message: "This is not a valid email!",
            },
            {
              required: true,
              message: "Please provide your email!",
            },
          ]}
        >
          <Input className="rounded-input" />
        </Form.Item>
        <Form.Item
          className="ant-form-item-mb"
          name={["password"]}
          label="Password"
          onChange={(e) => setPassword(e.target.value)}
          rules={[
            { 
              validator: (_, value) => 
                (!value || (value.length > 7 && value.length < 33)) 
                ? Promise.resolve() 
                : Promise.reject(`Password must be between ${8} and ${32} characters`) 
            },
            {
              required: true,
              message: "Please provide your password!",
            },
          ]}
        >
          <Input.Password className="rounded-input" />
        </Form.Item>
        <div className="signin-form-button">
          <button className="signUp-btn login-btn primary" type="submit">
            Login
          </button>

          <Link to="/forgot" className="forgot-link default">
            Forgot your password?
          </Link>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default LoginComponent;
