import React, { useContext, useState, useEffect } from 'react';
import { Tabs, Spin, notification } from 'antd';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import { AppContext } from '../context/globalState';
import { 
  Layout, 
  BikesFilter, 
  Search, 
  BikesList,
  BikesActionButtons,
  Modal,
  ReservationsList,
} from '../components';
import callApi from '../utils/callApi';
import sortByValue from '../utils/sorting';
import duplicateIcon from "../assets/dashboard/duplicate.svg";
import deleteIcon from "../assets/dashboard/delete.svg";
import useWindowDimensions from '../utils/useWindowDimensions';

const { TabPane } = Tabs;

const Dashboard = () => {
  const history = useHistory();
  const { viewport } = useWindowDimensions();
  const { user, isLoggedIn, setLoading } = useContext(AppContext);
  const [loader, setLoader] = useState(true);
  const [bikes, setBikes] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortBy, setSortBy] = useState('dateDue');
  const [filteredBikes, setFilteredBikes] = useState([]);
  const [nonRatedBikes, setNonRatedBikes] = useState([]);
  const [currentBike, setCurrentBike] = useState({});
  const [deleteBikeModal, setDeleteBikeModal] = useState(false);
  const [duplicateBikeModal, setDuplicateBikeModal] = useState(false);
  const [, updateState] = useState();
  const [activeTab, setActiveTab] = useState(history?.location?.state?.activeTab || 'bikes');

  useEffect(() => {
    const dataFetchInterval = setInterval(async () => {
      isLoggedIn && fetchBikes();
    }, 60 * 1000);

    return () => {
      dataFetchInterval && clearInterval(dataFetchInterval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const loadBikes = async () => {
      setLoader(true);
      await fetchBikes();
    }
    isLoggedIn && loadBikes();
  }, [isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let filtered = sortByValue(bikes, sortBy, sortDirection, searchQuery);
    setFilteredBikes(filtered);
    forceUpdate();
  }, [searchQuery, bikes?.length, sortBy, sortDirection, activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

  const forceUpdate = React.useCallback(() => updateState({}), []);

  const fetchBikes = async () => {
    const response = await callApi('get', 'bikes');
    if (!response?.error && response?.status !== 'error') {
      response?.bikes && setBikes(response?.bikes);
      if (user?.role === 'user' && !isEmpty(response?.reservations)) {
        setReservations(response?.reservations);

        const nonRatedBikesMap = {};
        response?.reservations
          ?.map(reservation => reservation?.bike)
          ?.forEach(bike => {
            if (!bike) return false;
            const originalBike = response?.bikes?.find(item => item?.bikeId === bike?.bikeId);
            if (!originalBike?.ratings?.[user?.uid]) {
              nonRatedBikesMap[bike?.bikeId] = bike;
            }
          });
        setNonRatedBikes(Object.values(nonRatedBikesMap));
      }
    }
    setLoading(false);
    setLoader(false);
  }

  const selectBike = async (bike, action) => {
    setCurrentBike(bike);
    switch (action) {
      case "copy":
        setDuplicateBikeModal(true);
        break;
      case "remove":
        setDeleteBikeModal(true);
        break
      default:
        return;
    }
  }

  const setAvailability = async (bikeId, available) => {
    try {
      await callApi('post', 'bike', { bikeId, available, operation: 'update' });
    } catch (error) {
      console.error(error)
    }
  }

  const duplicateBike = async (model) => {
    setDuplicateBikeModal(false);
    setLoader(true);
    await callApi('post', 'bike', { 
      model,
      bikeId: currentBike?.bikeId,
      operation: 'duplicate'
     });
    await fetchBikes();
  }

  const deleteBike = async (command) => {
    setDeleteBikeModal(false);
    if (command === 'DELETE') {
      setLoader(true);
      await callApi('post', 'bike', { 
        bikeId: currentBike?.bikeId,
        operation: 'delete'
      });
      await fetchBikes();
    } else {
      notification['warning']({
        duration: 10,
        message: 'Operation not confirmed',
        description: 'Type DELETE in the input field to confirm deletion'
      });
    }
  }

  const cancelReservation = async (reservation) => {
    setLoader(true);
    const response = await callApi('delete', `payment?paymentId=${reservation?.paymentId}`);
    if (!response?.error && response?.status !== 'error') {
      await fetchBikes();
      console.log('cancelReservation', nonRatedBikes)
    } else {
      notification['error']({
        duration: 10,
        message: 'Reservation error',
        description: response?.error
      });
    }
    setLoader(false);
  }

  const setRating = async (rating, bikeId) => {
    try {
      setLoader(true);
      await callApi('post', 'bike', { bikeId, rating, operation: 'rate' });
      await fetchBikes();
      setLoader(false);
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Layout>
      <div className="bikes-content-wrapper">
        <div className="bikes-main-menu">
          {
            user?.role === 'manager' && !loader &&
              <BikesActionButtons total={bikes && bikes?.length} />
          }
          {
            user?.role === 'user' && (
              <div className='bike-tabs-wrapper'>
                <Tabs 
                  centered 
                  tabBarGutter={50} 
                  defaultActiveKey={activeTab} 
                  onChange={setActiveTab}
                  animated={{ inkBar: false }}
                >
                  <TabPane tab={`All Bikes (${bikes?.length})`} key='bikes' className="bikes-all" />
                  <TabPane tab={`Reservations (${reservations?.length})`} key='reservations' className="bikes-completed" />
                </Tabs>
              </div>
            )
          }
        </div>
        {
          activeTab === 'bikes' && (
            <React.Fragment>
              <div className="bikes-filter">
                { 
                  searchQuery
                    ? <h3>Search Results</h3>
                    : <h3>All Bikes</h3>
                }
                <BikesFilter 
                  onChangeSort={setSortBy} 
                  onChangeSortDirection={setSortDirection} 
                  sortBy={sortBy}
                  direction={sortDirection}
                  role={user?.role}
                />
              </div>

              <Search 
                callback={setSearchQuery} 
                placeholder="Search for bike"
              />
            </React.Fragment>
          )
        }

        {
          loader 
            ? <Spin size="large" className="full-page-loader" />
            : (
              <React.Fragment>
                {
                  activeTab === 'bikes' ? (
                    <BikesList 
                      bikes={filteredBikes}
                      selectBike={selectBike}
                      role={user?.role}
                      setAvailability={setAvailability}
                    />
                  ) : (
                    <React.Fragment>
                      <ReservationsList 
                        reservations={reservations}
                        viewport={viewport}
                        onCancel={cancelReservation}
                      />
                      <BikesList 
                        bikes={nonRatedBikes}
                        role={user?.role}
                        rate={setRating}
                      />
                    </React.Fragment>
                  )
                }
              </React.Fragment>

            )
        }
      </div>
      <Modal 
        visible={duplicateBikeModal}
        icon={duplicateIcon}
        title={<h2>Duplicate <span className="highlight">{currentBike?.model}</span></h2>}
        subtitle="The bike is duplicated, however, reservations are removed in the duplicated bike." 
        placeholder="Type bike model here" 
        confirm="Duplicate Bike" 
        callback={duplicateBike}
        cancelCallback={() => setDuplicateBikeModal(false)}
        type="long"
      />
      <Modal 
        visible={deleteBikeModal}
        icon={deleteIcon}
        title={<h2 className="delete">Are you sure you want to delete bike <span className="highlight delete">{currentBike?.model}?</span></h2>}
        subtitle="Type DELETE in the box below to confirm" 
        placeholder="Type DELETE" 
        confirm="Confirm Delete" 
        callback={deleteBike}
        type="delete"
        targetValue="DELETE"
        cancelCallback={() => setDeleteBikeModal(false)}
      />
    </Layout>
  )
};

export default Dashboard;
