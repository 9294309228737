import React, { useContext, useState } from 'react';
import { Avatar as AvatarComponent, Menu } from 'antd';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { logout } from '../../utils/firebase';
import { AppContext } from '../../context/globalState';
import profileImage from "../../assets/common/profileImage.svg";
import closeIcon from "../../assets/common/closeIcon.svg";

const ProfileDropdown = () => {
  const { user, setLoggedIn } = useContext(AppContext);
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [mobileVisible, setMobileVisible] = useState(false);

  const toggleVisible = () => setVisible(visible => !visible);

  const handleClick = ({ key }) => {
    if (key === 'logout') {
      completeLogout();  
    } else {
      history.push(`/${key}`);
    }
  };

  const completeLogout = async () => {
    setLoggedIn(false);
    await localStorage.clear();
    await logout();
    history.push('/logout');
  };

  const userMenu = (
    <Menu 
      key="header-profile-user" 
      className="header-profile-user"
      onClick={handleClick}
      mode="inline"
    >
      <Menu.Item key="dashboard"><span className="dropdown-item">Dashboard</span></Menu.Item>
      <Menu.Item key="profile"><span className="dropdown-item">Profile</span></Menu.Item>
      <button className="primary" onClick={completeLogout}>Log out</button>
    </Menu>
  );

  const MobileMenu = (
    <div className="mobile-menu">
     {userMenu}
    </div>
  );

  const mobileMenuIcon = (
    <div className="mobile-header-menu-icon">
      <span 
        className={`menu ${mobileVisible ? 'toggled' : ''}`}
        onClick={() => setMobileVisible(!mobileVisible)}
      >
        <span></span>
        <span></span>
        <span></span>
      </span>
    </div>
  );

  const MainMenu = (
      <div className="main-menu-wrapper">
        <OutsideClickHandler
          onOutsideClick={() => setVisible(false)}
        >
          <React.Fragment>
            <div className="main-menu-header">
              <div className="main-menu-header-title">
                <h5 className="main-menu-user-name">{user?.displayName}</h5>
                <span className="main-menu-user-email">{user?.email}</span>
              </div>
              <img className="icon close" src={closeIcon} alt="" role="button" onClick={() => setVisible(false)} />
            </div>
            <div className="main-menu-items">
              <Link to={'/dashboard'} className="main-menu-item default">
                Dashboard
              </Link>
              <Link to={'/profile'} className="main-menu-item default">
                Profile
              </Link>
              <Link to={'/logout'} onClick={completeLogout} className="main-menu-item default">
                Sign Out
              </Link>
            </div>
          </React.Fragment>
        </OutsideClickHandler>
      </div>
  );

  return (
    <React.Fragment>
      {
        user?.uid && (
          <div role="button" className='avatar-wrapper' onClick={toggleVisible}>
            <AvatarComponent 
              gap={20} 
              size='small' 
              src={user?.photoURL || null}
              className="avatar"
              icon={user?.photoURL ? null : (
                <img src={profileImage} alt="" className="profile-image" />
              )} 
            />
            <h5 className="user-name">{user?.firstName}</h5>
          </div>
        )
      }
      { mobileMenuIcon }
      { mobileVisible && MobileMenu }
      { visible && MainMenu }
    </React.Fragment>
  );
};

export default ProfileDropdown;
