import React, { useState } from 'react';
import { Select } from 'antd';
import arrowRight from "../../assets/report/arrow-right.svg";
import arrowLeft from "../../assets/report/arrow-left.svg";

const { Option } = Select;

const UserSelector = ({ users, userId, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(userId);

  const usersArray = users?.map(user => user?.uid);

  const handleChange = (value) => {
    setSelectedValue(value);
    onChange(value);
  }

  const selectPrev = () => {
    const index = usersArray?.indexOf(selectedValue);
    if (index > 0) {
      setSelectedValue(usersArray?.[index - 1]);
      onChange(usersArray?.[index - 1]);
    }
  }

  const selectNext = () => {
    const index = usersArray?.indexOf(selectedValue);
    if (index < usersArray?.length - 1) {
      setSelectedValue(usersArray?.[index + 1]);
      onChange(usersArray?.[index + 1]);
    }
  }

  return (
    <div className="selector-wrapper user">
      <span  
        role="button"
        onClick={selectPrev}
        className={`select-button ${usersArray?.indexOf(selectedValue) <= 0 && 'disabled'}`}
      >
        <img className="arrow" src={arrowLeft} alt="" />
      </span>
      <Select onChange={handleChange} value={selectedValue}>
        {
          users?.map(user => (
            <Option 
              value={user?.uid} 
              key={user?.uid}
            >
              {user?.displayName}
            </Option>
          ))
        }
      </Select>
      <span  
        role="button"
        onClick={selectNext}
        className={`select-button ${usersArray?.indexOf(selectedValue) === usersArray?.length - 1 && 'disabled'}`}
      >
        <img className="arrow" src={arrowRight} alt="" />
      </span>
    </div>
  )
};

export default UserSelector;
