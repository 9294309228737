import { createContext, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import callApi from '../utils/callApi';
import { auth } from "../utils/firebase";
import { buildUserPayload } from "../utils/helpers";
import firebaseConfig from "../firebase.config";

export const AppContext = createContext({})

const GlobalState = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
	const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState([]);
  const [role, setRole] = useState('');

	async function readSession() {
    const user = await window.sessionStorage.getItem(
      `firebase:authUser:${firebaseConfig.apiKey}:[DEFAULT]`
    );
    if (user) {
			const storedUser = await localStorage.getItem('user');
			console.log('setLoggedIn 1', storedUser);
			storedUser && !isEmpty(storedUser) && setUser(JSON.parse(storedUser));
			console.log('setLoggedIn 2', JSON.parse(storedUser));
      setLoggedIn(true);
    }
  };

	useEffect(() => {
		try {
			setLoading(true);
			readSession();
			auth.onAuthStateChanged(async firebaseUser => {
				try {
					setLoading(false);
					const userFromRedirect = await auth.getRedirectResult()
						.catch(err => {
							setErrors([err?.message]);
							setLoading(false);
						});
					if (!isEmpty(userFromRedirect?.user)) {
						console.log('Call handleSignUp 11')
						await handleSignUp(userFromRedirect);
					} else if (!isEmpty(firebaseUser)) {
						console.log('Call handleSignUp 22')
						await handleSignUp({ user: firebaseUser });
					} 

					if (!firebaseUser) {
						await localStorage.clear();
						setUser({});
						setLoggedIn(false);
					}
				} catch (error) {
					setLoading(false);
					console.log('onAuthStateChanged', error)
				}
			})
		} catch (error) {
			setLoading(false);
			console.error('Authentication', error);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleSignUp = async (userData, params = {}) => {
		try {
			setLoading(true);
			const role = await localStorage.getItem('role');

			if (!isEmpty(userData?.user)) {
				let payload;
				if (userData?.additionalUserInfo) {
					payload = buildUserPayload({ 
						userData, role, ...params
					});
				} else {
					const storedUser = await localStorage.getItem('user');
					if (storedUser && !isEmpty(storedUser)) {
						console.log('Data from storage', storedUser)
						payload = storedUser && JSON.parse(storedUser);
					}
				}

				let idTokenResult;
				if (userData?.additionalUserInfo?.isNewUser) {
					const userResult = await callApi('put', 'user', payload);
					console.log('handleSignUp new', userResult, payload)
					userResult?.status !== 'success' && setErrors([...errors, userResult?.error]);
					payload = userResult?.userFirebase;
				} else {
					idTokenResult = await auth?.currentUser?.getIdTokenResult();
					console.log('handleSignUp 4', idTokenResult);
					
					const roleFromClaims = idTokenResult?.claims?.role;

					if (payload && !isEmpty(payload) && roleFromClaims) {
						await localStorage.setItem('role', roleFromClaims);
						setRole(roleFromClaims);
						payload.role = roleFromClaims;
						payload.signInProvider = idTokenResult?.signInProvider;
						console.log('handleSignUp existing', payload);
					}
				}
				console.log('Set logged in', payload && !isEmpty(payload), payload, !isEmpty(payload));
				payload && !isEmpty(payload) && await localStorage.setItem('user', JSON.stringify(payload));
				setUser(payload);
				// setLoggedIn(payload && !isEmpty(payload));
				setLoading(false);
				checkStoredUser(payload);
				// return payload && !isEmpty(payload);
			}
			console.log('handleSignUp ends', !isEmpty(userData?.user), userData?.user)
			setLoggedIn(!isEmpty(userData?.user));
		} catch (error) {
			console.error(error);
		}
	}

	const checkStoredUser = async (payload) => {
		try {
			let storedUserRecordResponse = await callApi('get', 'user');
			let storedUserRecord = storedUserRecordResponse?.userFirebase;
			if (payload && !isEmpty(payload) && !storedUserRecord) {
				const userResult = await callApi('put', 'user', payload);
				userResult?.status !== 'success' && setErrors([...errors, userResult?.error]);
				storedUserRecord = userResult?.userFirebase;
			}

			const userObj = { ...payload, ...storedUserRecordResponse?.user, ...storedUserRecordResponse?.userFirebase };
			setUser(userObj);
			userObj && !isEmpty(userObj) && await localStorage.setItem('user', JSON.stringify(userObj));
		} catch (error) {
			console.error(error);
		} 
	}

	const updateUserInfo = async (fields) => {
    try {
      const response = await callApi('post', 'updateUserInfo', { uid: user?.uid, ...fields });
      if (!response?.error && response?.status !== 'error') {
				const currentUser = { ...user, ...fields };
				if (currentUser && !isEmpty(currentUser)) {
					console.log('Current user', currentUser);
					setUser(currentUser);
        	await localStorage.setItem('user', JSON.stringify(currentUser));
				}
			} else {
        console.log('Update user error', response?.error);
      }
    } catch (err) {
      console.error('Error while updating user', err);
    }
  }

	return (
		<AppContext.Provider value={{
			isLoggedIn, setLoggedIn,
			user, setUser,
			role, setRole,
			loading, setLoading,
			errors, setErrors,
			updateUserInfo,
			handleSignUp,
		}}>
			{children}
		</AppContext.Provider>
	);
};

export default GlobalState;