import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';
import moment from 'moment';
import { DatePicker, Image, Skeleton } from 'antd';
import missingThumbnail from "../../assets/bike/missing.png";

const Conditions = () => (
  <ul className="dashboard-reservation-content">
    <li>Bike fitting and orientation</li>
    <li>Free helmet and lock</li>
    <li>Mobile Phone Holder</li>
    <li>Quick bike and safety instructions on request</li>
  </ul>
)

const ReservationCallout = ({ bike, reserved, price, reservation = null, callback = null }) => {
  const [time, setTime] = useState(null);
  const isExpired = !isEmpty(reservation) && reservation?.isActive === false;
  
  const disabledDate = current => {
    return (
      current < moment().startOf('day') ||
      reserved.find(date => moment(date).format("YYYY-MM-DD") === moment(current).format("YYYY-MM-DD"))
    );
  }

  return (
    <div className={`dashboard-reservation reservation ${isExpired ? 'expired' : ''}`}>
      <div className="bike-card-preview">
        <Image
          width={300}
          src={bike?.fileUrl}
          alt={bike?.title}
          placeholder={<Skeleton.Avatar active size="large" shape="square" className="skeleton-bikePreview" />}
          fallback={missingThumbnail}
        />
      </div>
      <h6 className="dashboard-reservation-header">Each bike rental includes:</h6>
      <Conditions />
      <DatePicker
        placeholder="Select rental date"
        format="ddd Do MMM YYYY HH:mm"
        disabledDate={disabledDate}
        showTime={true}
        onChange={setTime}
      />
      <button disabled={!time} onClick={() => callback(time)} className="medium green">Reserve Now - ${price}/hour</button>
      { 
        isExpired && (
          <a
            href={reservation?.hostedInvoice}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="medium dark">Download Invoice(s)</button>
          </a>
        )
      }
      {
        isExpired && reservation?.reservationEnd && (
          <p className="expiration-note">
            Reservation expired on {reservation?.reservationEnd && format(reservation?.reservationEnd, 'do MMM yyyy')}.
          </p>
        )
      }
    </div> 
  )
};

export default ReservationCallout;
