import React, { useState } from 'react';
import { Steps } from 'antd';
import useWindowDimensions from '../../utils/useWindowDimensions';

const { Step } = Steps;

const stepsMap = {
  upload: 0,
  configure: 1
}
const HeaderSteps = ({ location }) => {
  const { viewport } = useWindowDimensions();
  const currentStep = location.split('/')[3];
  const [current, setCurrent] = useState(stepsMap[currentStep]);

  console.log('Steps', currentStep, stepsMap[currentStep])
  const onChange = current => {
    console.log('onChange:', current);
    setCurrent(current);
  };

  return (
    <Steps
      type="navigation"
      current={current}
      onChange={onChange}
      className="site-navigation-steps"
    >
      <Step 
        status={currentStep === 'upload' ? 'process' : 'finish'} 
        title={'Bike Image'} 
        disabled={currentStep !== 'upload'}
      />
      <Step 
        status={currentStep === 'configure' ? 'process' : 'wait'} 
        title={viewport === 'tabletLandscape' ? 'Settings' : 'Bike Settings'} 
        disabled={currentStep !== 'configure'}
      />
    </Steps>
  );
}

export default HeaderSteps;
