import React, { useContext } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { 
  ForgotPassword,
  Login,
  SignUp,
  Dashboard,
  NotFoundPage,
  Manage,
  Profile,
  UploadBikeImage,
  ConfigureBike,
  Reservation,
  Report,
  Legal,
  Landing,
} from "./pages";
import { AppContext } from "./context/globalState";

const protectedRoutes = [
  {
    path: `/dashboard`,
    main: props => <Dashboard {...props} />,
  },
  {
    path: `/profile`,
    main: props => <Profile {...props} />,
  },
  {
    path: `/manage`,
    main: props => <Manage {...props} />,
  },
  {
    path: `/report/user/:userId`,
    main: props => <Report {...props} />,
  },
  {
    path: `/report/bike/:bikeId`,
    main: props => <Report {...props} />,
  },
  {
    path: `/reservation/:bikeId/:status/:sessionId`,
    main: props => <Reservation {...props} />,
  },
  {
    path: `/reservation/:bikeId/:status`,
    main: props => <Reservation {...props} />,
  },
  {
    path: `/reservation/:bikeId`,
    main: props => <Reservation {...props} />,
  },
  {
    path: `/bike/:bikeId/upload`,
    main: props => <UploadBikeImage {...props} />,
  },
  {
    path: `/bike/:bikeId/configure`,
    main: props => <ConfigureBike {...props} />,
  },
];

const App = () => {
  const { isLoggedIn } = useContext(AppContext);

  return (
    <BrowserRouter>
      <Switch>
        {
          protectedRoutes.map(route => (
            isLoggedIn 
              ? <Route key={route.path} path={route.path} component={route.main} />
              : <Route exact key={route.path} path={route.path} component={Login} />
          ))
        }
        <Route exact path={"/join-now"} component={SignUp} />
        <Route exact path={"/login/:email"} component={Login} />
        <Route exact path={"/login"} component={Login} />
        <Route exact path={"/forgot"} component={ForgotPassword} />
        <Route exact path={"/logout"} component={Login} />
        <Route exact path={"/legal"} component={Legal} />
        <Route exact path={"/landing"} component={Landing} />
        <Route exact path={"/"} component={Landing} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;