import React, { useState } from 'react';
import { Table } from 'antd';
import { compareAsc, format } from 'date-fns';
import { capitalize } from 'lodash';

const BikeUsersList = ({ reservations, viewport }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const columns = [
    {
      title: 'User Name',
      key: 'displayName',
      render: record => record?.user?.displayName,
      sorter: (a, b) => a?.user?.displayName?.localeCompare(b?.bike?.displayName),
      width: ['mobileLandscape', 'mobilePortrait'].includes(viewport) ? 150 : 175,
      fixed: 'left',
    },
    {
      title: 'User Email',
      key: 'email',
      render: record => record?.user?.email,
      sorter: (a, b) => a?.user?.email?.localeCompare(b?.user?.email),
      width: ['mobileLandscape', 'mobilePortrait'].includes(viewport) ? 150 : 175,
      fixed: 'left',
    },
    {
      title: 'Location',
      key: 'location',
      render: record => record?.bike?.location,
      sorter: (a, b) => a?.bike?.location?.localeCompare(b?.bike?.location),
      defaultSortOrder: 'ascend',
      width: 175,
    },
    {
      title: 'Reservation time',
      key: 'time',
      render: record => record?.time && format(new Date(record?.time), 'do MMM yyyy HH:mm'),
      sorter: (a, b) => compareAsc(new Date(a?.time), new Date(b?.time)),
      width: 195,
    },
    {
      title: 'Status',
      key: 'status',
      width: 145,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: record => capitalize(record?.status),
    },
  ];

  return (
    <React.Fragment>
      <Table
        scroll={{ x: 1200 }}
        pagination={reservations?.length > 20 && {
          showSizeChanger: true,
          defaultPageSize: 20,
          pageSizeOptions: [10, 20, 30, 40, 50],
          onShowSizeChange: (current, size) => {
            setPage(current);
            setPageSize(size)
          },
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize)
          },
          showTotal: (total, range) => {
            let pageCount = Math.ceil(total / pageSize);
            if (total === 0 && pageCount === 0) {
              pageCount = 1;
            }
            return `${page} of ${pageCount} page${pageCount > 1 ? 's' : ''}`;
          }
        }}
        dataSource={reservations}
        columns={columns}
        rowKey={record => record?.paymentId}
      />
      {
        viewport !== 'desktop' && (
          <span className="table-scroll-hint">
            {'Scroll sideways to view more data -->'}
          </span>
        )
      }
    </React.Fragment>
  )
};

export default BikeUsersList;
