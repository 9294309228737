import React, { useEffect, useState } from 'react';
import { Modal, Input } from 'antd';

const ModalPopUp = ({ visible, icon, title, subtitle, placeholder, confirm, callback, hint, type, cancelCallback, targetValue = null }) => {
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(visible);
  const [disabled, setDisabled] = useState(type === 'delete');

  useEffect(() => {
    setOpen(visible);
    setValue('');
  }, [visible]);

  const onSubmit = () => {
    callback(value);
    setOpen(false);
    setValue('');
  }

  const onCancel = () => {
    cancelCallback();
  }

  const onInputValueChange = e => {
    setValue(e.target.value);
    type === 'delete' && targetValue && setDisabled(e.target.value !== targetValue);
  }

  return (
    <Modal 
      visible={open} 
      closable={false}
      width={650}
      centered
      onCancel={onCancel}
      footer={null}
    >
      <div className="modal-popup">
        { icon && <img src={icon} alt="" className="modal-popup-icon" /> }
        <div className="modal-popup-text">
          {title}
          <h5 className="modal-popup-subtitle">{subtitle}</h5>
          <span className="modal-popup-hint">{hint}</span>
        </div>
        <Input 
          placeholder={placeholder} 
          type='text'
          className='input'
          onChange={onInputValueChange}
          value={value}
        />
        <div className="modal-popup-buttons">
          <button className="primary medium dark" onClick={onCancel}>
            Cancel
          </button>
          <button className={`primary medium ${type}`} onClick={onSubmit} disabled={disabled}>
            {confirm}
          </button>
        </div>
      </div>
    </Modal>
  )
};

export default ModalPopUp;
