import React, { useState } from 'react';
import { List, Empty } from 'antd';
import randomstring from 'randomstring';
import { useHistory } from 'react-router-dom';
import { ManagerBikeCard, UserBikeCard } from '..';

const BikesList = ({ bikes, role, rate = null, selectBike = null, setAvailability = null }) => {
  let history = useHistory();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  return (
    <List
      locale={{ emptyText: (
        <React.Fragment>
          {
            role ? null : (
              <Empty
                className="empty-list-wrapper"
                description={
                  <span className="empty-list">
                    No Bikes
                  </span>
                }
              >
                {
                  role === 'manager' ? (
                    <button 
                      type="primary"
                      onClick={() => history.push(`/bike/${randomstring.generate(8).toUpperCase()}/upload`)}
                    >
                      Create your first bike
                    </button>
                  ) : null
                }
              </Empty>
            )
          }
        </React.Fragment>
      )}}
      pagination={bikes?.length > 9 && {
        showSizeChanger: true,
        defaultPageSize: 9,
        pageSizeOptions: [9, 18, 27, 36, 45],
        onShowSizeChange: (current, size) => {
          setPage(current);
          setPageSize(size)
        },
        onChange: (page, pageSize) => {
          setPage(page);
          setPageSize(pageSize)
        },
        showTotal: (total, range) => {
          let pageCount = Math.ceil(total / pageSize);
          if (total === 0 && pageCount === 0) {
            pageCount = 1;
          }
          return `${page} of ${pageCount} page${pageCount > 1 ? 's' : ''}`;
        }
      }}
      grid={{
        gutter: 16,
        xs: 1,
        sm: 1,
        md: 1,
        lg: 2,
        xl: 3,
        xxl: 3,
      }}
      split={false}
      dataSource={bikes}
      renderItem={bike => (
        <List.Item key={bike?.bikeId} className="bikes-list-wrapper">
          {
            role === 'manager' ? (
              <ManagerBikeCard 
                bike={bike} 
                key={bike?.bikeId} 
                copy={() => selectBike(bike, 'copy')} 
                remove={() => selectBike(bike, 'remove')}
                setAvailability={setAvailability}
              />
            ) : (
              <UserBikeCard 
                bike={bike} 
                key={bike?.bikeId}
                rate={rate}
              />
            )
          }
        </List.Item>
      )}
    />
  )
};

export default BikesList;
