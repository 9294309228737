import React, { useState, useEffect } from 'react';
import { Upload, Tabs } from 'antd';
import UploadList from 'antd/lib/upload/UploadList';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { InboxOutlined } from '@ant-design/icons';
import { deleteFile, uploadFile } from '../utils/firebase';
import { Layout, SmallScreen, UploadTab, UploadByLink } from '../components';
import uploadIcon from "../assets/bike/uploadIcon.svg";
import arrow from "../assets/common/arrow-right.svg";
import arrowDisabled from "../assets/common/arrow-right-disabled.svg";
import callApi from '../utils/callApi';
import useWindowDimensions from '../utils/useWindowDimensions';

const { TabPane } = Tabs;
const { Dragger } = Upload;

const UploadBikeImage = () => {
  let history = useHistory();
  const { bikeId } = useParams();
  const { viewport, screenWidth } = useWindowDimensions();

  if (!bikeId) {
    history.push('/dashboard');
  }

  const [files, setFiles] = useState([]);
  const [done, setDone] = useState(false);
  const [path, setPath] = useState('');
  const [activeTab, setActiveTab] = useState('File');
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    const path = `bike-${bikeId}`;
    setPath(path);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const processFiles = async () => {
    if (fileList?.length > 0) {
      callApi('put', 'upload', { bikeId, fileData: fileList?.[0], uploadByLink: true });
    }

    redirect();
  }

  const redirect = () => history.push(`/bike/${bikeId}/configure`, { files });

  const handleUpload = async ({ onSuccess, onError, file, onProgress }) => {
    setDone(false);
    await uploadFile(file, uploadSuccessCallback, path, onSuccess, onError, onProgress);
  };

  const handleDeletion = async file => {
    await deleteFile(file?.name, path);
  };

  const uploadSuccessCallback = async fileURL => {
    try {
      console.log('uploadSuccessCallback', fileURL);
      return true;
    } catch (err) {
      console.error('Error while registering activity', err);
    }
  };

  const onChange = info => {
    const files = info?.fileList?.map(file => ({
      name: file?.name,
      lastModified: file?.lastModified,
      size: file?.size,
      status: file?.status,
      type: file?.type,
      percent: file?.percent,
      path,
      url: file?.response
    }));

    const isDone = (files?.filter(file => file?.status === 'done' /*|| file?.percent === 100*/))
      ?.length === info?.fileList?.length;
    
    if (isDone) {
      setFiles(files);
      setDone(isDone);
      // Prepare bike document & thumbnail
      callApi('put', 'upload', { bikeId });
    }
  }

  const uploadProps = {
    customRequest: handleUpload,
    onRemove: handleDeletion,
    onChange,
    multiple: false,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    accept: ".png,.jpg,.jpeg"
  }

  const onFileListUpdate = (fileList, itemToRemove = null) => {
    const list = itemToRemove ? fileList.filter(({ name }) => name !== itemToRemove?.name) : fileList;
    setFileList(list);
    setFiles(list);
    setDone(list?.length > 0);
  }

  if (screenWidth <= 580) {
    return <SmallScreen viewport={viewport} />
  }

  return (
    <Layout>
      <div className="bikes-upload-page">
        <div className="bikes-upload-page-callout-wrapper">
          <div className="bikes-upload-page-callout">
            <h2>Upload bike image</h2>
          </div>
          <img className="callout-icon" src={uploadIcon} alt="" />
        </div>
        <div className="dragger-wrapper">
          <div className='upload-tabs-wrapper'>
            <Tabs 
              tabBarGutter={50} 
              defaultActiveKey="File" 
              onChange={tab => setActiveTab(tab) || setFileList([])}
              animated={{ inkBar: false }}
            >
              <TabPane tab={<UploadTab type="File" isActive={activeTab === 'File'} />} key="File" />
              <TabPane tab={<UploadTab type="Link" isActive={activeTab === 'Link'} />} key="Link" />
            </Tabs>
          </div>
          {
            activeTab === 'File' && (
                <Dragger {...uploadProps}>
                  <div className="upload-button-wrapper">
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click here or drag a file to this area to upload</p>
                  </div>
                </Dragger>
              )
           }
           {
            activeTab === 'Link' && (
                <div className="upload-by-link-wrapper">
                  <UploadByLink onFileListUpdate={onFileListUpdate} />
                  <UploadList
                    items={fileList}
                    locale={{}}
                    onRemove={item => onFileListUpdate(fileList, item)}
                    showRemoveIcon={true}
                    showUploadList={{ showRemoveIcon: true }}
                  />
                </div>
              )
          }
          <div className="cta-wrapper">
            <button 
              className="primary" 
              onClick={processFiles}
              disabled={!done}
            >
                Next &nbsp;&nbsp;
                <img className="arrow" src={done ? arrow : arrowDisabled} alt="" />
            </button>
          </div>
        </div>
      </div>
    </Layout>
  )
};

export default UploadBikeImage;
