import React, { useEffect, useState } from 'react';
import { Spin, Tabs, notification } from 'antd';
import { isEmpty } from 'lodash';
import callApi from '../utils/callApi';
import removeIcon from "../assets/manage/removeIcon.svg";
import inviteIcon from "../assets/manage/inviteIcon.svg";
import { Layout, Modal, Search, UsersList, ManagersList } from "../components";
import useWindowDimensions from '../utils/useWindowDimensions';

const { TabPane } = Tabs;

const Manage = () => {
  const { viewport } = useWindowDimensions();
  const [loader, setLoader] = useState(true);
  const [miniLoader, setMiniLoader] = useState(false);
  const [managers, setManagers] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredManagers, setFilteredManagers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('users');
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [inviteManagerModal, setInviteManagerModal] = useState(false);
  const [inviteUserModal, setInviteUserModal] = useState(false);
  const [userToRemove, setUserToRemove] = useState({});

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    activeTab === 'managers' 
      ? setFilteredManagers(managers?.filter(filter))
      : setFilteredUsers(users?.filter(filter));
  }, [searchQuery, activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

  const filter = (entry) => {
    const emailMatch = entry?.email?.toLowerCase().includes(searchQuery.toLowerCase());
    const firstNameMatch = entry?.firstName?.toLowerCase().includes(searchQuery.toLowerCase());
    const lastNameMatch = entry?.lastName?.toLowerCase().includes(searchQuery.toLowerCase());
    return firstNameMatch || lastNameMatch || emailMatch;
  }

  const loadData = async () => {
    const response = await callApi('get', 'users');
    console.log('loadData response', response);
    setManagers(response?.managers);
    setUsers(response?.users);
    setFilteredUsers(response?.users);
    setFilteredManagers(response?.managers);
    setLoader(false);
  }

  const onRemoveUser = async (user) => {
    setRemoveUserModal(true);
    setUserToRemove(user);
  }

  const onRemoveManager = async (manager) => {
    setRemoveUserModal(true);
    setUserToRemove(manager);
  }

  const onUpdateUser = async (userData, mode = null) => {
    try {
      // setMiniLoader(true);
      const response = await callApi('patch', 'user', { ...userData, mode });
      console.log('onAddUser response', response);
      response?.managers && setManagers(response?.managers);
      response?.managers && setFilteredManagers(response?.managers);
      response?.users && setUsers(response?.users);
      response?.users && setFilteredUsers(response?.users);
      // setMiniLoader(false);
    } catch (error) {
      console.error(error);
    }
  }

  const removeUser = async (command) => {
    setRemoveUserModal(false);
    if (command === 'REMOVE' && !isEmpty(userToRemove)) {
      setMiniLoader(true);
      const response = await callApi('post', 'users', { 
        userId: userToRemove?.uid,
        type: userToRemove?.role,
        operation: 'remove' 
      });
      console.log('removeUser response', response);
      response?.managers && setManagers(response?.managers);
      response?.managers && setFilteredManagers(response?.managers);
      response?.users && setUsers(response?.users);
      response?.users && setFilteredUsers(response?.users);
      setMiniLoader(false);
    } else {
      notification['warning']({
        duration: 10,
        message: 'Operation not confirmed',
        description: 'Type REMOVE in the input field to confirm'
      });
    }
  }

  const inviteManager = async (email) => {
    setInviteManagerModal(false);
    if (!isEmpty(email)) {
      if (validateEmail(email)) {
        setMiniLoader(true);
        const response = await callApi('post', 'users', { 
          email,
          type: 'manager',
          operation: 'invite' 
        });
        console.log('inviteManager response', response);
        // setManagers(response?.managers);
        // setFilteredManagers(response?.managers);
        loadData();
        setMiniLoader(false);
      } else {
        notification['error']({
          duration: 10,
          message: `Manager's email is invalid`,
        });
      }
    } else {
      notification['error']({
        duration: 10,
        message: `Manager's email is required`,
      });
    }
  }

  const inviteUser = async (email) => {
    setInviteUserModal(false);
    if (!isEmpty(email)) {
      if (validateEmail(email)) {
        setMiniLoader(true);
        const response = await callApi('post', 'manage', { 
          email,
          type: 'user',
          operation: 'invite' 
        });
        console.log('inviteUser response', response);
        loadData();
        setMiniLoader(false);
      } else {
        notification['error']({
          duration: 10,
          message: `User's email is invalid`,
        });
      }
    } else {
      notification['error']({
        duration: 10,
        message: `User's email is required`,
      });
    }
  }

  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const Actions = () => (
    <React.Fragment>
      {
        activeTab === 'users' ? (
          <button className="primary small dark invite-btn" onClick={() => setInviteUserModal(true)}>
            Invite user
          </button>
        ) : (
          <button className="primary small dark invite-btn" onClick={() => setInviteManagerModal(true)}>
            Invite manager
          </button>
        )
      }
    </React.Fragment>
  )

  return (
    <Layout>
      <div className="manage-page">
        {
          loader 
            ? <Spin size="large" className="full-page-loader" /> 
            : (
              <React.Fragment>
                <div className="stats">
                  <span className="hint">
                    {users?.length} user(s), {managers?.length} manager(s)
                  </span>
                </div>
                <div className='manage-tabs-wrapper'>
                  <Tabs 
                    centered
                    tabBarGutter={50} 
                    defaultActiveKey='users' 
                    onChange={setActiveTab}
                    animated={{ inkBar: false }}
                  >
                    <TabPane tab='Users' key='users' className="users" />
                    <TabPane tab='Managers' key='managers' className="managers" />
                  </Tabs>
                </div>
                { !['desktop', 'tabletLandscape'].includes(viewport) && <Actions /> }
                {
                  activeTab === 'managers' 
                    ? <div className="counter">{filteredManagers?.length} Manager(s)</div>
                    : <div className="counter">{filteredUsers?.length} User(s)</div>
                }
                <div className={`search-actions ${activeTab}`}>
                  <Search 
                    callback={setSearchQuery} 
                    placeholder={activeTab === 'managers' ? 'Type Manager Name' : 'Type User Name'}
                    label={activeTab === 'managers' ? 'Search for Manager' : 'Search for User'}
                  />
                  { ['desktop', 'tabletLandscape'].includes(viewport) && <Actions /> }
                </div>
                {
                  miniLoader 
                    ? <Spin size="large" className="full-page-loader" /> 
                    : (
                      <React.Fragment>
                        {
                          activeTab === 'managers' ? (
                            <ManagersList 
                              managers={filteredManagers}
                              onUpdate={onUpdateUser}
                              onRemove={onRemoveManager}
                              viewport={viewport}
                            />
                          ) : (
                            <UsersList 
                              users={filteredUsers}
                              onUpdate={onUpdateUser}
                              onRemove={onRemoveUser}
                              viewport={viewport}
                            />
                          )
                        }
                      </React.Fragment>
                    )
                }
              </React.Fragment>
            )
        }
      </div>
      <Modal 
        visible={inviteUserModal}
        icon={inviteIcon}
        title={<h2>Invite a user</h2>}
        subtitle="Type their email. We’ll send them an email to join" 
        placeholder="Type user’s email here" 
        confirm="Add user" 
        callback={inviteUser}
        cancelCallback={() => setInviteUserModal(false)}
      />
      <Modal 
        visible={inviteManagerModal}
        icon={inviteIcon}
        title={<h2>Invite a manager to add and edit bikes</h2>}
        subtitle="Type their email. We’ll send them an email to join" 
        placeholder="Type manager’s email here" 
        confirm="Add manager" 
        callback={inviteManager}
        cancelCallback={() => setInviteManagerModal(false)}
      />
      <Modal 
        visible={removeUserModal}
        icon={removeIcon}
        title={<h2 className="delete">Are you sure you want to remove <span className="highlight delete">{userToRemove?.firstName} {userToRemove?.lastName || 'manager'}?</span></h2>}
        subtitle="Type REMOVE in the box below to confirm" 
        placeholder="REMOVE" 
        confirm={`Remove ${activeTab === 'managers' ? 'Manager' : 'User'}`} 
        callback={removeUser}
        hint={`Removing a ${activeTab === 'managers' ? 'manager' : 'user'} will remove all of their data. This process is not reversible.`}
        type="delete"
        targetValue="REMOVE"
        cancelCallback={() => setRemoveUserModal(false)}
      />
    </Layout>
  )
}

export default Manage;
