import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Form, Input, Spin, notification } from 'antd';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../context/globalState';
import callApi from '../utils/callApi';
import arrow from "../assets/common/arrow-right.svg";
import googleLogo from "../assets/signup/google-logo.png";
import microsoftLogo from "../assets/signup/microsoft-logo.png";
import { Layout, Modal } from "../components";
import { auth, updateWith } from "../utils/firebase";

const Profile = () => {
  let history = useHistory();
  const { user, setUser } = useContext(AppContext);
  const [loader, setLoader] = useState(true);
  const [payload, setPayload] = useState({});
  const [, updateState] = useState();
  const [changeEmailModal, setChangeEmailModal] = useState(false);
  const [profileForm] = Form.useForm();

  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (user && !isEmpty(user)) {
      setLoader(false);
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateUserData = async (formValues, currentPassword = null) => {
    const values = profileForm?.getFieldsValue();
    console.log('updateUserData', values, user);

    if (values?.firstName && values?.firstName !== user?.firstName) {
      payload.firstName = values?.firstName;
    }
    if (values?.lastName && values?.lastName !== user?.lastName) {
      payload.lastName = values?.lastName;
    }
    if (values?.email && values?.email !== user?.email) {
      payload.email = values?.email;
      if (!currentPassword) {
        setChangeEmailModal(true);
        return;
      } else {
        setChangeEmailModal(false);
        try {
          // try to login
          await auth.signInWithEmailAndPassword(user?.email, currentPassword);
        } catch (error) {
          notification['error']({
            duration: 10,
            message: 'Wrong Password',
            description: error?.message
          });
        }
      }
    }
    delete payload?.password;
    delete payload?.old_password;
    setPayload(payload);
    console.log('updateUserData payload', payload);

    if (!isEmpty(payload)) {
      setLoader(true);
      const response = await callApi('post', 'user', payload);
      console.log('updateUserData response', response);
      const useData = { ...user, ...payload };
      useData.displayName = `${useData.firstName} ${useData.lastName}`;
      console.log('updateUserData useData', useData);
			await localStorage.setItem('user', JSON.stringify(useData));
      setUser(useData);
      setLoader(false);
      if (!response?.error && response?.status !== 'error') {
        notification['success']({
          duration: 10,
          message: 'Profile updated',
          description: 'Your profile was successfully updated'
        });
      }
    };

    if (values?.old_password && values?.password) {
      const successCallback = () => {
        notification['success']({
          duration: 10,
          message: 'Password updated',
          description: 'Your password was successfully updated'
        });
      }
      await updateWith(user?.signInProvider, 'password', errorCallback, { 
        email: values?.email || user?.email,
        oldPassword: values?.old_password,
        newPassword: values?.password
      }, successCallback);
    }
  }

  const errorCallback = (error) => {
    notification['error']({
      duration: null,
      message: 'Password reset error',
      description: error
    });
  }

  const onUpdate = (data) => {
    const key = data?.[0]?.name?.[0];
    const value = data?.[0]?.value;
    if (value !== '') {
      payload[key] = value;
    }
    if (value === user?.[key]) {
      delete payload[key];
    }
    setPayload(payload);
    forceUpdate();
  }

  if (!user?.role === 'manager') {
    history.push('/dashboard');
  }

  return (
    <Layout>
      <div className="profile-page">
        {
          loader 
            ? <Spin size="large" className="full-page-loader" /> 
            : (
              <Form 
                form={profileForm}
                size="large" 
                layout="vertical" 
                name="profile-form" 
                className="profile-form"
                hideRequiredMark 
                onFinish={updateUserData} 
                onFieldsChange={onUpdate}
                initialValues={{ 
                  firstName: user?.firstName || '',
                  lastName: user?.lastName || '',
                  email: user?.email || '',
                }}
              >
                {
                  user?.signInProvider === 'password' ? (
                    <React.Fragment>
                      {
                        user?.role === 'manager' && (
                          <div className="name-inputs-wrapper">
                            <Form.Item
                              label="First Name" 
                              name="firstName"
                              className="name-input"
                              autoComplete="off" 
                              rules={[
                                {
                                  required: true,
                                  message: "Please provide your first name!",
                                },
                                {
                                  min: 2,
                                  max: 64,
                                  message: "First name must be longer than 1 character",
                                },
                              ]}
                            >
                              <Input className="rounded-input"/>
                            </Form.Item>
                            <Form.Item
                              label="Last Name" 
                              name="lastName"
                              className="name-input"
                              autoComplete="off" 
                              rules={[
                                {
                                  required: true,
                                  message: "Please provide your last name!",
                                },
                                {
                                  min: 2,
                                  max: 64,
                                  message: "Last name must be longer than 1 character",
                                },
                              ]}
                            >
                              <Input className="rounded-input"/>
                            </Form.Item>
                          </div>
                        )
                      }
                      <Form.Item
                        name="email"
                        label="Email"
                        autoComplete="off" 
                        rules={[
                          {
                            type: "email",
                            message: "This is not a valid email!",
                          },
                          {
                            required: true,
                            message: "Please provide your email!",
                          },
                        ]}
                      >
                        <Input className="rounded-input"/>
                      </Form.Item>
                      <div className="change-password">
                        <h3 className="change-password-label">
                          Change Password
                        </h3>
                        <Form.Item
                          name="old_password"
                          label="Old Password"
                          autoComplete="off" 
                          rules={[
                            { 
                              validator: (_, value) => 
                                (!value || (value.length > 7 && value.length < 33)) 
                                ? Promise.resolve() 
                                : Promise.reject(`Password must be between ${8} and ${32} characters`) 
                            },
                            {
                              required: profileForm.getFieldValue('password')?.length > 0,
                              message: "Please provide your old password!",
                            },
                          ]}
                        >
                          <Input.Password className="rounded-input" />
                        </Form.Item>
                        <Form.Item
                          name="password"
                          label="New Password"
                          autoComplete="off" 
                          rules={[
                            { 
                              validator: (_, value) => 
                                (!value || (value.length > 7 && value.length < 33)) 
                                ? Promise.resolve() 
                                : Promise.reject(`Password must be between ${8} and ${32} characters`) 
                            },
                          ]}
                        >
                          <Input.Password className="rounded-input" />
                        </Form.Item>
                      </div>
                      </React.Fragment>
                    ) : (
                      <div className="signup-cta-wrapper">
                        <button onClick={() => updateWith(user?.signInProvider, 'google.com', errorCallback)} className="google-login-btn" type="button">
                          <img className="google-logo" src={googleLogo} alt="logo" />
                          Update to new Google Account
                          <div />
                        </button>
                        <button onClick={() => updateWith(user?.signInProvider, 'microsoft.com', errorCallback)} className="microsoft-login-btn" type="button">
                          <img className="microsoft-logo" src={microsoftLogo} alt="logo" />
                          Update to new Microsoft Account
                          <div />
                        </button>
                      </div>
                    )
                }
                {
                  user?.signInProvider === 'password' && (
                    <button 
                      className="primary" 
                      type="submit"
                      disabled={isEmpty(payload)}
                    >
                      Save
                      <img className="arrow" src={arrow} alt="" />
                    </button>
                  )
                }

              </Form>
            )
        }
      </div>
      <Modal 
        visible={changeEmailModal}
        title={<h2>Confirm Email Change</h2>}
        subtitle="Please enter your account password to confirm this change" 
        placeholder="Type password here" 
        confirm="Change Email" 
        callback={password => updateUserData(null, password)}
        cancelCallback={() => setChangeEmailModal(false)}
      />

    </Layout>
  )
}

export default Profile;
