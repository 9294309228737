import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Space } from 'antd';
import { isEmpty } from 'lodash';
import { AppContext } from "../context/globalState";
import { signInWithGoogle, signInWithMicrosoft, signInWithCredentials } from "../utils/firebase";
import { Layout, SignUpForm, SignUpError } from "../components";
import googleLogo from "../assets/signup/google-logo.png";
import microsoftLogo from "../assets/signup/microsoft-logo.png";
import arrowLeft from "../assets/common/arrow-left-small.svg";

const SignUp = () => {
  let role = 'user';
  const history = useHistory();
  const { 
    isLoggedIn, 
    user, 
    setLoading,
    setRole,
    handleSignUp,
    errors, 
    setErrors,
  } = useContext(AppContext);
  const [showEmailForm, switchEmailForm] = useState(false);

  useEffect(() => {
    saveRole();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    saveRole();
  }, [role]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    callback();
    setLoading(false);
  }, [user, JSON.stringify(user)]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log('SignUp isLoggedIn', isLoggedIn, user);
    isLoggedIn && history.push('/dashboard')
  }, [isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  async function saveRole() {
    await localStorage.setItem('role', role);
    setRole(role);
  }

  const callback = async () => {
    switchEmailForm(false);
  };

  const onFinish = (params) => {
    setLoading(true);
    signInWithCredentials("createUser", params, onFinishCallback, onFinishErrorCallback);
  };

  const onFinishErrorCallback = async (result) => {
    setErrors([result]);
    setLoading(false);
  };

  const onFinishCallback = async (userData, params) => {
    role && await localStorage.setItem('role', role);
    const result = await handleSignUp(userData, params);
    console.log('onFinishCallback', result, isLoggedIn, userData, params)
    result && history.push('/dashboard')
  };

  const getSignUpForm = () => 
    isEmpty(user) && showEmailForm ? emailSignUp() : ctaButtons()

  const ctaButtons = () => (
    <React.Fragment>
      {
        isEmpty(user) && (
          <div className="signup-cta-wrapper">
            <button onClick={signInWithGoogle} className="google-login-btn" type="button">
              <img className="google-logo" src={googleLogo} alt="logo" />
              Sign up with Google
              <div />
            </button>
            <button onClick={signInWithMicrosoft} className="microsoft-login-btn" type="button">
              <img className="microsoft-logo" src={microsoftLogo} alt="logo" />
              Sign up with Microsoft
              <div />
            </button>
            <div className="signUpType-switcher">
              or &nbsp;<span role="button" onClick={() => switchEmailForm(true)}>
                Sign up with email
              </span>
            </div>
          </div>
        )
      }
    </React.Fragment>
  )

  const emailSignUp = () => (
    <React.Fragment>
      <div className="signUpType-switcher">
        <span role="button" onClick={() => switchEmailForm(false)}>
          {'<'} Choose other method
        </span>
      </div>
      <SignUpForm callback={onFinish} />
    </React.Fragment>
  )

  return (
    <Layout>
      <div className="signUp-main-section">
        { isLoggedIn && !isEmpty(user) ? (
            <h2>Hello, {user?.firstName}!</h2> 
          ) : (
            <Link to="/login" className="back-btn-wrapper">
              <button className="back-btn">
                <img className="arrow-left" src={arrowLeft} alt="" />
                 Back
              </button>
            </Link>
          )
        }

        <Space size={25}>
          <SignUpError errors={errors} />
        </Space>
        { getSignUpForm() }
      </div>
    </Layout>
  );
};

export default SignUp;
