import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { capitalize } from 'lodash';
import LoadingOverlay from 'react-loading-overlay-ts';
import { AppContext } from "../../context/globalState";
import { Logo, Footer, Header, ProfileDropdown, HeaderSteps } from '..';
import useWindowDimensions from '../../utils/useWindowDimensions';

const Layout = ({ children, header = true }) => {
  let history = useHistory();
  const { viewport } = useWindowDimensions();
  const { role, setRole, setUser, loading, isLoggedIn, setLoggedIn, setErrors } = useContext(AppContext);
  const [contentLeft, setContentLeft] = useState();
  const [contentRight, setContentRight] = useState();
  const [contentMiddle, setContentMiddle] = useState();

  const location = history.location.pathname;
  useEffect(() => {
    if (isLoggedIn && (['/login', '/logout', '/join-now'].includes(location) || location === '/dashboard')) {
      if (role) {
        history.push('/dashboard');
      } else {
        setLoggedIn(false);
        setUser({});
        setRole('');
        history.push('/join-now');
      }
    }
    setHeaderContent(location);
    setErrors([]);
  }, [isLoggedIn, location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setHeaderContent(location);
  }, [viewport]); // eslint-disable-line react-hooks/exhaustive-deps

  const setHeaderContent = location => {
    if (location.split('/')?.[1] === 'logout') {
      setContentMiddle(<Logo external />);
    } else if (isLoggedIn && ['dashboard', 'report', 'profile', 'reservation', 'manage'].includes(location.split('/')?.[1])) {
      setContentLeft(<Logo />);
      setContentRight(<ProfileDropdown />);
      setContentMiddle((
        <h3 className="header-middle-label">
          {capitalize(location.split('/')?.[1])}
        </h3>
      ));
    } else if (isLoggedIn && (location.includes('/upload') || location.includes('/configure'))) {
      setContentLeft(<Logo />);
      setContentRight(<ProfileDropdown />);
      setContentMiddle(<HeaderSteps location={location} />);
    } else {
      setContentLeft();
      setContentRight();
      setContentMiddle(<Logo external />);
    }
  };

  return (
    <div className="app-wrapper">
      <LoadingOverlay
        active={loading}
        spinner
        text='Loading. Please wait…'
      >
        {
          header && (
            <Header 
              contentLeft={contentLeft} 
              contentMiddle={contentMiddle} 
              contentRight={contentRight}
            />
          )
        }
        <div className="app">
          {children}
        </div>
        <Footer />
      </LoadingOverlay>
    </div>
  );
}

export default Layout;