import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Image, Skeleton, Tooltip, Rate, Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import statsIcon from "../../assets/bike/statsIcon.svg";
import configureIcon from "../../assets/bike/configureIcon.svg";
import duplicateIcon from "../../assets/dashboard/duplicateIcon.svg";
import deleteIcon from "../../assets/dashboard/deleteIcon.svg";
import missingThumbnail from "../../assets/bike/missing.png";
import { getTimeDue } from '../../utils/time';
import { isValidGPS } from '../../utils/helpers';
import { googleMaps } from '../../config.json';

const ManagerBikeCard = ({ bike, copy, remove, setAvailability }) => {
  return (
    <Card style={{ width: 300 }} className="manager-view">
      <div className="bike-card-manager">
        <div className="bike-card-header">
          <Link to={`/report/bike/${bike?.bikeId}`} className="default">
            <img src={statsIcon} alt="" className="bike-stats-icon" />
            {bike?.reservationsCount} Reservation(s)
          </Link>
        </div>
        <div className="bike-card-body">
          <div className="bike-card-preview">
            <Image
              width={250}
              src={bike?.fileUrl}
              alt={bike?.title}
              placeholder={<Skeleton.Avatar active size="large" shape="square" className="skeleton-bikePreview" />}
              fallback={missingThumbnail}
            />
          </div>
          <div className="bike-card-title">
            <p>{bike?.model}</p>
            <span className="hint description">{bike?.description}</span>
            <span className="hint">Available: &nbsp;&nbsp;
              <Switch
                size="small" 
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                name="availability" 
                onChange={checked => setAvailability(bike?.bikeId, checked)}
                defaultChecked={bike?.available}
              />
            </span>
            <span className="hint">Color: {bike?.color}</span>
            <span className="hint">Location: &nbsp;
              {
                typeof bike?.location === 'string' && isValidGPS(bike?.location) 
                ? (
                  <a 
                    href={`${googleMaps}${bike?.location}`} 
                    target="_blank"
                    rel="noopener noreferrer"
                    className="location"
                  >
                    {bike?.location}
                  </a>
                ) : bike?.location
              }
            </span>
            <span className="hint">Created: {getTimeDue(bike?.dateCreated, true)}</span>
            <span className="hint">Rating: {
              bike?.rating ? <><Rate allowHalf disabled defaultValue={bike?.rating} /> ({bike?.rating})</>
              : 'Not rated'
            }
            </span>
          </div>
        </div>
        <div className="bike-card-footer">
          <div className="class-card-actions">
            <Tooltip title="Manage" color="#247CE3">
              <Link to={`/bike/${bike?.bikeId}/configure`}>
                <img 
                  src={configureIcon} 
                  alt="Manage" 
                  className="bike-card-action" 
                />
              </Link>
            </Tooltip>
            <Tooltip title="Duplicate" color="#247CE3">
              <img 
                src={duplicateIcon} 
                alt="Duplicate" 
                className="bike-card-action" 
                role="button" 
                onClick={copy} 
              />
            </Tooltip>
            <Tooltip title="Delete" color="#247CE3">
              <img 
                src={deleteIcon} 
                alt="Delete" 
                className="bike-card-action" 
                role="button" 
                onClick={remove} 
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </Card>
  )
};

export default ManagerBikeCard;
