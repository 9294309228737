import React from 'react';
import { useHistory } from 'react-router-dom';
import logo from "../../assets/common/favicon.png";
import desktop from "../../assets/common/desktop.svg";
import rotate from "../../assets/common/rotate-screen.svg";

const SmallScreen = ({ viewport }) => {
  let history = useHistory();

  return (
    <div className="blocked-page">
      <img src={logo} alt="Logo" className="logo" />
      <div className="callout-wrapper">
        {
          viewport === 'tabletPortrait'
          ? (
            <React.Fragment>
              <img src={rotate} alt="rotate screen" className="rotate" />
              <h2>Rotate to Landscape</h2>
              <p>Please rotate your tablet to view this page.</p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <img src={desktop} alt="" className="desktop" />
              <p>Please use a larger screen device to access this page such as a tablet or laptop.</p>
            </React.Fragment>
          )
        }
      </div>
      <button className="medium blocked-back" onClick={() => history.goBack()}>
        Back
      </button>
    </div>
  )
};

export default SmallScreen;
