import React, { useState } from "react";
import { Form, Input, Space } from "antd";
import { SignUpError } from '..';
import arrow from "../../assets/common/arrow-right.svg";

const SignUpForm = ({ callback }) => {
  const [errors, setError] = useState([]);
  const [registerForm] = Form.useForm();

  const onFinish = (values) => {
    onChange();
    setError([])
    callback(values);
  }

  const setErrors = ({ errorFields }) => {
    const errors = errorFields.map(({ errors }) => errors[0].replace('!','.'));
    setError(errors)
  };

  const onChange = async () => {
    registerForm.validateFields().catch(errorObj => {
      const errors = errorObj?.errorFields.map(error => error?.errors?.[0]);
      setError(errors);
    });
  }

  return (
    <div className="register-form-wrapper">
      <h2>Sign up with Email</h2>
      <Form
        form={registerForm}
        size="large"
        layout="vertical"
        name="register-form"
        className="register-form"
        hideRequiredMark
        onFinish={onFinish}
        onFinishFailed={setErrors}
        // onValuesChange={onChange}
      >
        <Space size={25}>
          <SignUpError errors={errors} />
        </Space>
        <div className="name-inputs-wrapper">
          <Form.Item
            name="firstName"
            label="First Name"
            className="name-input"
            rules={[
              {
                required: true,
                message: "Please provide your first name!",
              },
              {
                min: 2,
                max: 64,
                message: "First name must be longer than 1 character",
              },
            ]}
          >
            <Input className="rounded-input" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            className="name-input"
            rules={[
              {
                required: true,
                message: "Please provide your last name!",
              },
              {
                min: 2,
                max: 64,
                message: "Last name must be longer than 1 character",
              },
            ]}
          >
            <Input className="rounded-input" />
          </Form.Item>
        </div>
        <Form.Item
          name="email"
          label={'Email Address'}
          rules={[
            {
              type: "email",
              message: "This is not a valid email!",
            },
            {
              required: true,
              message: "Please provide your email!",
            },
          ]}
        >
          <Input className="rounded-input" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            { 
              validator: (_, value) => 
                (!value || (value.length > 7 && value.length < 33)) 
                ? Promise.resolve() 
                : Promise.reject(`Password must be between ${8} and ${32} characters`) 
            },
            {
              required: true,
              message: "Please provide your password!",
            },
          ]}
        >
          <Input.Password className="rounded-input" />
        </Form.Item>
        <div className="signup-form-button">
          <button className="signUp-btn primary" type="submit">
            Get Started &nbsp;&nbsp;
            <img className="arrow" src={arrow} alt="" />
          </button>
        </div>
      </Form>
    </div>
  );
};

export default SignUpForm;
