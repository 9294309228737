import { format, differenceInDays, isToday } from 'date-fns';

export const getTimeLimit = timeLimit => {
  if (!timeLimit) {
    return 'No time limit';
  } else {
    if (timeLimit > 0) {
      let hours = 0;
      let minutes = Math.trunc(timeLimit / 60);
      if (minutes > 59) {
        hours = Math.trunc(minutes / 60);
        minutes = minutes - (hours * 60);
      }

      if (minutes === 0) {
        if (hours === 1) {
          return '1 hour time limit';
        } else {
          return `${hours} hours time limit`;
        }
      } else {
        return `${minutes} minute time limit`;
      }
    }
  }
}

export const getTimeDue = (dueTime, short = true) => {
  if (!dueTime) {
    return 'No Due Date';
  }
  if (short) {
    return format(dueTime, 'do MMM yyyy');
  }
  return `Due ${format(dueTime, 'h:mmaaa eee do MMM yyyy')}`;
}

export const getTimeDueManager = dueTime => {
  if (!dueTime) {
    return 'No Due Date';
  }
  return format(dueTime, 'eee do MMM yyyy (h:mmaaa)');
}

export const getTimeDueHeader = (dueTime, status) => {
  const diff = differenceInDays(dueTime, new Date());

  if (status === 'completed') {
    return 'Done';
  } else if (status === 'completed_late') {
    return 'Done Late';
  } else if (!dueTime) {
    return 'No due date';
  } else if (diff === 0 && dueTime > new Date()) {
    if (isToday(dueTime)) {
      return `Due today`;
    } else {
      return `Due tomorrow`;
    }
  } else if (diff > 0 && diff % 7 === 0) {
    if (diff / 7 === 1) {
      return `Due in 1 week`;
    } else {
      return `Due in ${diff / 7} weeks`;
    }
  } else if (diff === 1) {
    return `Due in 1 day`;
  } else if (diff > 0) {
    return `Due in ${diff} days`;
  } else if (diff < 0) {
    return `Overdue by ${Math.abs(diff)} days`;
  } else if (diff === 0 && dueTime <= new Date()) {
    return `Overdue today`;
  }
  return '';
  ;
}

export const getTimeString = (timeTaken) => {
  if (timeTaken > 0) {
    let hours = 0;
    let minutes = Math.trunc(timeTaken / 60);
    if (minutes > 59) {
      hours = Math.trunc(minutes / 60);
      minutes = minutes - (hours * 60);
    }
    const seconds = timeTaken - (minutes * 60) - (hours * 3600);
    if (hours > 0) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else if (minutes > 0) {
      return `${minutes}m ${seconds}s`;
    } else {
      return `${seconds}s`;
    }
  }
  return "0m 0s";
}