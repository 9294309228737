import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Modal, Spin, notification } from 'antd';
import { AppContext } from "../context/globalState";
import callApi from '../utils/callApi';
import { Layout, SignUpError } from "../components";
import forgotPasswordImg from "../assets/signup/forgot-password.svg";

const ForgotPassword = () => {
  let history = useHistory();
  const [modal, contextHolder] = Modal.useModal();
  const { setLoggedIn, errors, setErrors } = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);

  const callback = () => {
    const config = {
      title: "Password reset",
      content: (
        <div>
          <p>We have sent a reset password email to your email</p>
        </div>
      ),
      onOk: () => {
        setLoggedIn(false);
        history.push("/dashboard");
      }
    };
    modal.info(config);
  };

  const onFinish = async () => {
    setLoader(true);
    const response = await callApi('post', 'password', { email });
    setLoader(false);
    if (response?.status === 'success') {
      notification['info']({
        duration: 7,
        message: 'Password reset',
        description: 'Please check your email!'
      });
      callback();
    } else {
      notification['error']({
        duration: null,
        message: 'Password reset error',
        description: response?.message
      });
      setErrors([response?.message]);
    }
  };

  const setLoginErrors = (errorObj) => {
    if (errorObj?.message) {
      setErrors([errorObj?.message]);
      console.log('Login err1', errorObj?.message)

    } else if (errorObj?.errorFields?.length) {
      const loginErrors = errorObj?.errorFields?.map(({ errors }) => errors[0].replace('!','.')) || [];
      setErrors(loginErrors);
      console.log('Login err2', loginErrors)

    }
  };

  return (
    <Layout>
      <div className="signUp-main-section forgot-password-page">
        <img src={forgotPasswordImg} alt="forgot password" className="password-logo" />
        <h2>Reset Your Password</h2> 
        <div className="sign-in-switcher">
          Enter your email address and we'll send you instructions for creating a new password.
        </div>
        <br />
        {
          loader 
            ? <Spin size="large" className="full-page-loader" /> 
            : (
              <React.Fragment>
                <div>
                  <SignUpError errors={errors} />
                </div>
                <Form 
                  size="large" 
                  layout="vertical" 
                  name="forgot-password-form" 
                  hideRequiredMark 
                  onFinish={onFinish} 
                  onFinishFailed={setLoginErrors}
                  validateTrigger="onSubmit"
                >
                  <Form.Item
                    name={["email"]}
                    label="Email Address"
                    onChange={(e) => setEmail(e.target.value)}
                    rules={[
                      {
                        type: "email",
                        message: "This is not a valid email!",
                      },
                      {
                        required: true,
                        message: "Please provide your email!",
                      },
                    ]}
                  >
                    <Input className="rounded-input" />
                  </Form.Item>
                  <br />
                  <button className="reset-password-btn signUp-btn primary" type="submit">
                    Reset Password
                  </button>
                </Form>
              </React.Fragment>
            )
        }
        {contextHolder}
      </div>
    </Layout>
  );
};

export default ForgotPassword;
