import React from 'react';
import 'antd/dist/antd.css';
import './styles/index.scss';
import GlobalState from './context/globalState';
import Router from "./Router";
import loadFonts from './utils/fonts';

loadFonts();

const App = () => (
    <GlobalState>
      <Router />
    </GlobalState>
);

export default App;
