import { Link } from "react-router-dom";
import { Image, Skeleton } from 'antd';
import mainImage from "../assets/landing/bike-rental.jpg";
import logo from "../assets/common/logo.png";
import location from "../assets/landing/location.png";
import cityBike from "../assets/landing/city-bike.jpg";
import eBike from "../assets/landing/e-bike.png";
import mountainBike from "../assets/landing/mountain-bike.png";
import sportyBike from "../assets/landing/sporty-bike.png";
import trekkingBike from "../assets/landing/trekking-bike.png";
import woodenBike from "../assets/landing/wooden-bike.jpg";
import missingThumbnail from "../assets/bike/missing.png";

const cards = [
  {
    title: 'CITY BIKE',
    header: 'OUR CITY BIKES',
    body: 'On our city bike in retro look you always make a good figure. Equipped with a 3-speed Shimano hub gear and back pedal brake our city bikes are comfortable and safe, but also athletic enough to cover longer distances in the city.',
    price: 10,
    image: cityBike
  },
  {
    title: 'E-BIKE',
    header: 'POWERFUL THROUGH THE CITY',
    body: 'Enjoy with us the ease of the new cycling. The city runners are really fun. Long range, secure wide tires and a 7 gear shift. This pays off especially on a big city bike tour. Cycling does not have to be exhausting anymore.',
    price: 35,
    image: eBike
  },
  {
    title: 'SPORTY BIKE',
    header: 'OUR TOUR BIKES',
    body: 'With our purist Tour Bikes you are well prepared for longer bike tours through the city. 7 gears and a sporty frame geometry for men and women, thus making cycling in the city really fun. This bike is perfect for those who really want to discover everything in the city.',
    price: 14,
    image: sportyBike
  },
  {
    title: 'TREKKING BIKE',
    header: 'URBAN – TREKKING BIKES',
    body: 'Our urban city bikes is great fun if you want to explore the city on sporting manner. Carbon belt drive, 8-speed hub gear and 6 bar on the tires. So a round trip on the city centre or on one of the many, wide avenues makes really joy.',
    price: 20,
    image: trekkingBike
  },
  {
    title: 'MOUNTAIN BIKE',
    header: 'OUR MOUNTAIN BIKES',
    body: 'If you think you can not mountain biking in the city, you should rent a MTB. Decide for yourself, whether you want to bomb down through the forest or field or simply cruise through the city. With our 27.5 “and 29” MTB’s you always have real fun.',
    price: 20,
    image: mountainBike
  },
  {
    title: 'WOODY BIKE',
    header: 'DESIGN WOODEN BIKE',
    body: 'Our wooden bike is a true design piece and for all who love the special. Wherever you are on the road, you and the bike will put a smile on the faces of the people. It is equipped with a backpedal brake and a 2-gear automatic transmission.',
    price: 25,
    image: woodenBike
  }
]
const Landing = () => (
  <div className="landing-main-wrapper">
    <div className="landing-top-menu">
      <img className="logo" src={logo} alt="bike rental logo"/>
      <Link to={'/login'}>Login</Link>
    </div>
    <div className="landing-main-image">
      <img className="main-image" src={mainImage} alt="bike rental"/>
    </div>
    <Link to={'/dashboard'} className="landing-callout">
      <h1>Your rental bike - reserve now!</h1>
      <Link to={'/dashboard'}>BIKE RESERVATION</Link>
    </Link>
    <div className="landing-content white">
      <h2>TOP BIKE RENTAL</h2>
      __________
      <br />
      <h4>Rent bicycles for every need</h4>
      <p>The range of rental bikes is huge. But finding the right bike for your stay in the city is not that easy. That’s why we specializes in providing the right and top kept bike for every visitor. Our bikes are available in all sizes, as women’s and men’s bikes. For the offspring we offer youth and children’s bikes, of course with the matching helmet. For the little ones, of course, you also get a child seat with us. So a family bike ride through the city, nothing stands in the way. You can rent our bikes for 1 hour, 1 day, the whole week or even for several months. You must then care for nothing. We take care of the maintenance and at the end of your stay in the city you give the bike just back. Come visit us at the bike rental store. There you will also find other useful little things for a relaxed bike ride through Berlin, as well as lots of tips and suggestions for your city discoveries. You are with a larger group in Berlin? No problem, we have enough bikes in our bike rental.</p>
      <h4>Large selection of rental bikes for the whole family</h4>
      <p>In our Bike Rental Store waits a wide selection of rental bikes. In addition to our city men’s and women’s bicycles, you can also rent sporty mountain bikes or the first sustainable wooden bike. Sporty, sustainable-stylish, comfortable or with an extra kick of electronic support. In our bike rental you get the right bike for rent.</p>
      <br />
      <p>With us you get rented bicycles at the best prices, very straightforward and without having to download an app or register somewhere. You just keep your bike as long as you want. The longer, the cheaper are our bike-rental prices. And you can also reserve bicycles with us for a larger group tour.</p>
      <br />
      <p>There are several types of bikes to choose from, from our sturdy and comfortable city bike to our stylish wooden bike to our sporty mountain or trekking bikes.</p>
    </div>
    <div className="landing-content grey">
      <h4>RENT OUR BIKES!</h4>
      <div className="bike-cards">
        {
          cards.map(card => (
            <div className="bike-card" key={card.title}>
              <div className="bike-card-header">
                {card.title}
              </div>
              <Image
                className="bike-card-img"
                width={300}
                src={card.image}
                alt={card.title}
                placeholder={<Skeleton.Avatar active size="large" shape="square" className="skeleton-bikePreview" />}
                fallback={missingThumbnail}
              />
              <h4>{card.header}</h4>
              __________
              <br />
              <br />
              <p>{card.body}</p>
              <br />
              <p><strong>Charge</strong> from {card.price}€ a day</p>
              <p><strong>Start</strong> from 10am or upon request</p>
              <p><strong>Info</strong> incl. bike lock, helmet on request</p>
              <br />
              <Link className="bike-reservation" to={'/dashboard'}>BIKE RESERVATION</Link>
            </div>
          ))
        }
      </div>
    </div>
    <div className="landing-content white">
      <h2>LOCATION</h2>
      __________
      <br />
      <br />
      <img className="location" src={location} alt="location" />
    </div>
    <div className="landing-content dark">
      <h2>CONTACT</h2>
      __________
      <br />
      <br />
      <h4>+49 (0) 30 000 000 00</h4>
      <h4><a href="mailto:hello@lexer.dev">hello@lexer.dev</a></h4>
    </div>
  </div>
)

export default Landing;