const sortByValue = (bikes, sortBy, sortDirection, searchQuery = '') => {
  let result = bikes;

  if (searchQuery) {
    result = bikes?.filter(item =>
      item?.model?.toLowerCase().includes(searchQuery?.toLowerCase()) 
      || item?.color?.toLowerCase().includes(searchQuery?.toLowerCase()) 
      || item?.location?.toLowerCase().includes(searchQuery?.toLowerCase()) 
      || item?.description?.toLowerCase().includes(searchQuery?.toLowerCase()) 
      || item?.rating?.toString().toLowerCase().includes(searchQuery?.toLowerCase()) 
    )
  }

  if (sortDirection === 'asc') {
    switch (sortBy) {
      case 'model':
        result = result.sort((x, y) => {
          const a = x?.model.toLowerCase();
          const b = y?.model.toLowerCase();
          return a === b ? 0 : a > b ? 1 : -1;
        });
        break;

      case 'availability':
        result = result.sort((x, y) => {
          return (x?.available === y?.available)? 0 : x?.available ? -1 : 1;
        });
        break;

      case 'rating':
        result = result.sort((x, y) => {
          return (x?.rating || 0) - (y?.rating || 0);
        });
        break;

      case 'dateCreated':
      default:
        result = result.sort((x, y) => {
          const a = new Date(x?.dateCreated);
          const b = new Date(y?.dateCreated);
          return a - b;
        });
    }
  } else {
    switch (sortBy) {
      case 'model':
        result = result.sort((x, y) => {
          const a = x?.model.toLowerCase();
          const b = y?.model.toLowerCase();
          return a === b ? 0 : a < b ? 1 : -1;
        });
        break;

      case 'availability':
        result = result.sort((x, y) => {
          return (x?.available === y?.available)? 0 : x?.available ? 1 : -1;
        });
        break;

      case 'rating':
        result = result.sort((x, y) => {
          return (y?.rating || 0) - (x?.rating || 0);
        });
        break;

      case 'dateCreated':
      default:
        result = result.sort((x, y) => {
          const a = new Date(x?.dateCreated);
          const b = new Date(y?.dateCreated);
          return b - a;
        });
    }
  }

  return result;
}

export default sortByValue;