import React, { useState } from 'react';
import { Select } from 'antd';
import arrowRight from "../../assets/report/arrow-right.svg";
import arrowLeft from "../../assets/report/arrow-left.svg";

const { Option } = Select;

const BikeSelector = ({ bikes, bikeId, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(bikeId);

  const bikesArray = bikes?.map(bike => bike?.bikeId);

  const handleChange = (value) => {
    setSelectedValue(value);
    onChange(value);
  }

  const selectPrev = () => {
    const index = bikesArray?.indexOf(selectedValue);
    if (index > 0) {
      setSelectedValue(bikesArray?.[index - 1]);
      onChange(bikesArray?.[index - 1]);
    }
  }

  const selectNext = () => {
    const index = bikesArray?.indexOf(selectedValue);
    if (index < bikesArray?.length - 1) {
      setSelectedValue(bikesArray?.[index + 1]);
      onChange(bikesArray?.[index + 1]);
    }
  }

  return (
    <div className="selector-wrapper user">
      <span  
        role="button"
        onClick={selectPrev}
        className={`select-button ${bikesArray?.indexOf(selectedValue) <= 0 && 'disabled'}`}
      >
        <img className="arrow" src={arrowLeft} alt="" />
      </span>
      <Select onChange={handleChange} value={selectedValue}>
        {
          bikes?.map(bike => (
            <Option 
              value={bike?.bikeId} 
              key={bike?.bikeId}
            >
              {bike?.model}
            </Option>
          ))
        }
      </Select>
      <span  
        role="button"
        onClick={selectNext}
        className={`select-button ${bikesArray?.indexOf(selectedValue) === bikesArray?.length - 1 && 'disabled'}`}
      >
        <img className="arrow" src={arrowRight} alt="" />
      </span>
    </div>
  )
};

export default BikeSelector;
