/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Image, Skeleton } from 'antd';
import { format } from 'date-fns';
import missingThumbnail from "../../assets/bike/missing.png";

const Conditions = () => (
  <ul className="dashboard-reservation-content">
    <li>For the rental we need a valid photo ID</li>
    <li>Rental is always possible at our opening hours.</li>
    <li>A theft insurance can be concluded for €3.99/day </li>
  </ul>
)

const FullAccessCallout = ({ bike, reservation, price, onCancel }) => {
  console.log('reservation', reservation)
  return (
    <div className={`dashboard-reservation reservation success ${reservation?.isCancelled ? 'cancelled' : ''}`}>
      {
        reservation?.status === 'Reservation cancelled' ? (
          <React.Fragment>
            <div className="invoice-wrapper">
              <p className="reservation-start-date">Reservation for <br />{reservation?.time && format(new Date(reservation?.time), 'do MMM yyyy')} cancelled</p>
            </div>
            <div className="bike-card-preview">
              <Image
                width={300}
                src={bike?.fileUrl}
                alt={bike?.title}
                placeholder={<Skeleton.Avatar active size="large" shape="square" className="skeleton-bikePreview" />}
                fallback={missingThumbnail}
              />
            </div>
            <h6 className="dashboard-reservation-header">Reservation cancelled</h6>
            <div className="external-links">
              <a
                href={reservation?.hostedReceipt}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="medium purple">Download Receipt</button>
              </a>
            </div>
          </React.Fragment>
          ) : (
          <React.Fragment>
            <div className="invoice-wrapper">
              <p className="reservation-start-date">Reservation for <br />{reservation?.time && format(new Date(reservation?.time), 'do MMM yyyy')} confirmed</p>
              <p>at €{price}/hour</p>
            </div>
            <div className="bike-card-preview">
              <Image
                width={300}
                src={bike?.fileUrl}
                alt={bike?.title}
                placeholder={<Skeleton.Avatar active size="large" shape="square" className="skeleton-bikePreview" />}
                fallback={missingThumbnail}
              />
            </div>
            <h6 className="dashboard-reservation-header">Important notes:</h6>
            <Conditions />
            <div className="external-links">
              <a
                href={reservation?.hostedReceipt}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="medium purple">Download Receipt</button>
              </a>
              <a
                href="#"
                className="default external-link"
                onClick={onCancel}
              >
                Cancel reservation
              </a>
            </div>
          </React.Fragment>
        )
      }
    </div> 
  )
};

export default FullAccessCallout;
