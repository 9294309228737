import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { Layout, ConfigureBikeForm, SmallScreen } from "../components";
import { AppContext } from '../context/globalState';
import useWindowDimensions from '../utils/useWindowDimensions';

const ConfigureBike = () => {
  const { viewport, screenWidth } = useWindowDimensions();

  let history = useHistory();
  const { bikeId } = useParams();
  const { user } = useContext(AppContext);

  if (!bikeId) {
    history.push('/dashboard');
  }

  if (screenWidth <= 580) {
    return <SmallScreen viewport={viewport} />
  }

  return (
    <Layout>
      <ConfigureBikeForm userType={user?.type} />
    </Layout>
  );
}

export default ConfigureBike;
