import React from 'react';
import { Link } from 'react-router-dom';
import randomstring from 'randomstring';
import useWindowDimensions from '../../utils/useWindowDimensions';
import manageIcon from "../../assets/bike/manageIcon.svg";
import newBikeIcon from "../../assets/bike/newBikeIcon.svg";

const BikesActionButtons = () => {
  const { viewport } = useWindowDimensions();

  return (
    <div className="bikes-cta-wrapper">
      <Link 
        to={{
          pathname: `/bike/${randomstring.generate(8).toUpperCase()}/upload`,
        }}
        className="bikes-create-new"
      >
        <button className="primary large" disabled={viewport === 'tabletPortrait'}>
          <img className="icon" src={newBikeIcon} alt="logo" />
          Create New Bike
        </button>
        {
          viewport === 'tabletPortrait' && (
            <span className="rotate-hint">
              Rotate tablet to landscape
            </span>
          )
        }
      </Link>
      <Link to={`/manage`} className="bikes-manage-class">
        <button className="primary large purple">
          <img className="icon" src={manageIcon} alt="logo" />
          Manage Users
        </button>
      </Link>
    </div>
  )
};

export default BikesActionButtons;
