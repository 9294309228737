import React from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from 'antd';
import image from "../assets/common/not-found.png";
import { Layout } from "../components";

const { Panel } = Collapse;

const ErrorPage = ({ error, componentStack }) => {
  return (
    <Layout>
      <div className="not-found-page">
        <img src={image} alt="Page not found" className="not-found-image" />
        <h1>Oops! You have encountered an error.</h1>
        <Link to="/dashboard">
          <button className='primary medium'>
            Go to home
          </button>
        </Link>
        <br /><br /><br />
        <div className="error-fallback">
          <Collapse style={{ width: '50vw' }}>
            {
              error && (
                <Panel header="Error" key="1">
                  <p>{error?.toString()}</p>
                </Panel>
              )
            }
            {
              componentStack && (
                <Panel header="Component Stack" key="2">
                  <p>{componentStack}</p>
                </Panel>
              )
            }
          </Collapse>
        </div>
      </div>
    </Layout>
  )
}

export default ErrorPage;
