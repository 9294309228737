import React from 'react';
import Icon from '@ant-design/icons';
import { ReactComponent as FileIcon } from '../../assets/bike/fileIcon.svg';
import { ReactComponent as LinkIcon } from '../../assets/bike/linkIcon.svg';

const UploadTab = ({ type, isActive }) => {
  return (
    <div className={`upload-tab ${isActive && 'active'}`}>
      {
        type === 'Link' 
          ? <Icon component={() => <LinkIcon />} /> 
          : <Icon component={() => <FileIcon />} />
      }
      { type }
    </div>
  )
};

export default UploadTab;
