const buildUserPayload = ({ userData, role, firstName = null, lastName = null, anonymous = null }) => {
  const provider = userData?.additionalUserInfo?.providerId;
  if (provider === 'google.com') {
    const profile = userData?.additionalUserInfo?.profile;
    return {
      role,
      firstName: profile?.given_name,
      lastName: profile?.family_name,
      email: profile?.email,
      displayName: profile?.name,
      uid: userData?.user?.uid,
      language: profile?.locale,
      photoURL: profile?.picture,
    }
  } else if (provider === 'microsoft.com') {
    const profile = userData?.additionalUserInfo?.profile;
    return {
      role,
      firstName: profile?.givenName,
      lastName: profile?.surname,
      email: userData?.user?.email,
      displayName: profile?.displayName,
      uid: userData?.user?.uid,
      language: userData?.user?.ya,
      photoURL: userData?.user?.photoURL,
    }
  } else {
    if (firstName && lastName) {
      const userObj = {
        role,
        firstName,
        lastName,
        email: userData?.user?.email,
        displayName: `${firstName} ${lastName}`,
        uid: userData?.user?.uid,
        language: userData?.user?.ya,
        photoURL: null
      }
      if (anonymous) {
        userObj.anonymous = true;
      }
      return userObj;
    } else if (userData?.user?.displayName) {
      const [first, last] = userData?.user?.displayName.split(' ');
      return {
        role,
        firstName: first,
        lastName: last,
        email: userData?.user?.email,
        displayName: userData?.user?.displayName,
        uid: userData?.user?.uid,
        language: userData?.user?.ya,
        photoURL: null
      }
    }
  }
};

const isValidGPS = (gps) => {
    const [lat, lon] = gps.replace(/ /g, '').split(',');
    const latRegex = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
    const lonRegex = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;
    
    if (latRegex.test(Number(lat)) && lonRegex.test(Number(lon))) {
        return true;
    }
    return false;
  }

export {
  buildUserPayload,
  isValidGPS
};