import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../../assets/common/logo.png";
import logoMobile from "../../assets/common/favicon.png";

const Logo = ({ external }) => {
  return (
    <Link to={external ? '/login' : '/dashboard'}>
      <img src={logo} alt="Logo" className="logo" />
      <img src={logoMobile} alt="Logo" className="logo-mobile" />
    </Link>
  )
};

export default Logo;
