const Legal = () => (
  <div className="legal-wrapper">
    <div className="legal-content">
      <h3>Copyright Notice</h3>
      <p>All Rights Reserved.</p>
      <p>All material appearing on the Bike Rental website (https://bike-rentals.lexer.dev) (“content”) is protected by copyright under german copyright laws and is the property of Alexey Sobolev. You may not copy, reproduce, distribute, publish, display, perform, modify, create derivative works, transmit, or in any way exploit any such content, nor may you distribute any part of this content over any network, including a local area network, sell or offer it for sale, or use such content to construct any kind of application. You may not alter or remove any copyright or other notice from copies of the content on Bike Rental website (https://bike-rentals.lexer.dev). <strong>Copying or storing any content is expressly prohibited without prior written permission of Alexey Sobolev.</strong></p>
    </div>
    <div className="legal-content">
      <h3>Disclaimer</h3>
      <p>The content contained in Bike Rental website (https://bike-rentals.lexer.dev) is provided only for demonstration and informational purposes or as required by German law. Alexey Sobolev attempts to ensure that content is accurate and obtained from reliable sources, but does not represent it to be error-free. Alexey Sobolev may add, amend or repeal any policy, procedure or regulation, and failure to timely post such changes to its website shall not be construed as a waiver of enforcement. Alexey Sobolev does not warrant that any functions on its website will be uninterrupted, that defects will be corrected, or that the website will be free from viruses or other harmful components. Any links to third party information on the Bike Rental website (https://bike-rentals.lexer.dev) are provided as a courtesy and do not constitute an endorsement of those materials or the third party providing them.</p>
    </div>
  </div>
)

export default Legal;