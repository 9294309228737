import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import firebaseConfig from "../firebase.config";

!firebase.apps.length && firebase.initializeApp(firebaseConfig);
firebase.auth().useDeviceLanguage();

const auth = firebase.auth();
const storage = firebase.storage();

const logout = async (callback) => {
  auth.signOut()
    .then(callback)
    .catch(error => console.error("An error happened", error));
}

const updateWith = (currentProvider, newProvider, errorCallback, params = null, successCallback = null) => {
  let provider;
  if (currentProvider === 'google.com') {
    provider = new firebase.auth.GoogleAuthProvider();
  } else if (currentProvider === 'microsoft.com') {
    provider = new firebase.auth.OAuthProvider('microsoft.com');
  } else if (currentProvider === 'password') {
    firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
      auth.signInWithEmailAndPassword(params?.email, params?.oldPassword)
        .then(async firebasUser => {
          if (params?.newPassword) {
            // https://firebase.google.com/docs/auth/web/manage-users
            firebasUser?.user.updatePassword(params?.newPassword).then(() => {
              if (successCallback) {
                successCallback();
              }
            }).catch((error) => errorCallback(error?.message));
          }
        })
        .catch(error => errorCallback(error?.message));
    });
    return;
  }

  if (currentProvider === newProvider && newProvider !== 'password') {
    auth.signInWithRedirect(provider);
  }
  auth
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
      // https://firebase.google.com/docs/auth/web/account-linking#unlink-an-auth-provider-from-a-user-account
      auth.currentUser.unlink(provider?.providerId).then(() => {
        auth
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(async () => {
            if (newProvider === 'google.com') {
              provider = new firebase.auth.GoogleAuthProvider();
            } else if (newProvider === 'microsoft.com') {
              provider = new firebase.auth.OAuthProvider('microsoft.com');
            }
            auth.signInWithRedirect(provider);
            auth.currentUser.linkWithRedirect(provider);
          });
      }).catch((error) => {
        errorCallback(error);
      });
    });
};

const signInWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  auth
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
      auth.signInWithRedirect(provider);
    });
};

const signInWithMicrosoft = () => {
  const provider = new firebase.auth.OAuthProvider('microsoft.com');
  auth
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => 
        auth.signInWithRedirect(provider)
    );
};

const signInWithCredentials = (action, params, callback, errorCallback) => {
  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
      auth[`${action}WithEmailAndPassword`](params?.email, params?.password)
        .then(async firebasUser => {
          callback(firebasUser, params);
        })
        .catch(error => errorCallback(error?.message));
    });
};

const uploadFile = async (file, callback, path, onSuccess, onError, onProgress) => {
  const promise = new Promise(async (resolve, reject) => {
    try {
      if (file) {
        let uploadTask = storage.ref(path + file?.name).put(file);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + Math.trunc(progress) + '% done');
            onProgress({ percent: Math.trunc(progress) });
            if (Math.trunc(progress) === 100) {
              console.log('uploadFile progress', Math.trunc(progress));
              setTimeout(() => {
                console.log('Set file upload as complete', file?.name, snapshot.state);
                // set to completed if uploading is stuck
                onSuccess();
              }, 100000); 
            }
          }, 
        (error) => {
          console.log("uploadFile error", error);
          onError({ err: error });
          reject();
        }, 
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
            onSuccess(downloadURL);
            callback(downloadURL);
            resolve();
          });
        }
        );
      } else {
        reject();
      }
    } catch (error) {
      console.log('uploadFile error', error);
      reject();
    }
  });

  return promise;
};

const deleteFile = async (file, path) => {
  try {
    storage.ref(path + file)
      .delete()
      .catch(error => console.log('Delete file error', error))
  } catch (error) {
    console.log(error);
  }
}

export {
  auth,
  logout,
  signInWithGoogle,
  signInWithMicrosoft,
  signInWithCredentials,
  storage,
  deleteFile,
  uploadFile,
  updateWith,
};
